import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import NavBar from '../components/dashboard-nav';
import DataTable from './data-table';
import { supabase_userid } from '../utils/supabase-utils';
import { X } from 'react-feather';
import useTeamInfo from '../utils/get-team';


function FileData() {
    const { teamId, teamLoading } = useTeamInfo();
    const { fileId } = useParams();
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [fileName, setFileName] = useState([]);

    useEffect(() => {
        const fetchFileData = async () => {
            try {
                const userId = await supabase_userid;
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/files/${fileId}`, {
                    headers: {
                      'X-User-ID': userId,
                      ...(teamId && { 'X-Team-ID': teamId })
                    }
                  });
                if (response && response.data) {
                    const { filename, columns, data } = response.data;
                    console.log(response.data);
                    // Set column headers
                    setColumns(columns.map((columnName, index) => ({
                        Header: columnName,
                        accessor: String(index), // Use index as accessor
                    })));
                    setData(data);
                    setFileName(filename);
                } else {
                    console.error("Empty response")
                }
            } catch (error) {
                console.error('Error fetching file data:', error);
            }
        };
        if (!teamLoading) {
            fetchFileData();
        }
    }, [teamLoading, fileId, teamId]);
    
   

    return (
        <div className="mt-20 p-10 sm:p-20 flex flex-col gap-y-5">
            < NavBar />
            <div className='flex flex-row justify-between gap-x-8'>
                <div className='flex flex-col sm:flex-row gap-x-8 text-wrap'>
                    <h1 className="text-3xl sm:text-4xl"> {fileName} </h1>
                    <div className='flex gap-x-2 flex-col whitespace-nowrap'>
                        <p className="text-sm"> {data.length} Rows </p>
                        <p className="text-sm"> {columns.length} Columns </p>
                    </div>
                </div>
                <button className="flex items-center h-fit p-x-2"><Link to="/data"> < X size={40} /> </Link></button>    
            </div>
            < DataTable columnsRaw={columns} data={data} showCount={20} exportName={fileName} />
        </div>
    );
}

export default FileData;
