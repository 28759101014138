import {React, useState, useEffect } from 'react';
import { Card, SparkAreaChart } from '@tremor/react';



const KeyResult = ({ data, showname, color }) => {
    const [progress, setProgress] = useState(null);
    const [goal, setGoal] = useState(null);
    const columns = data.kpi.columns;


    useEffect(() => {
      if (data && data.kpi.goal && !isNaN(data.kpi.goal)) {
          setGoal(Number(data.kpi.goal));
          setProgress(data.kpi.value * 100 / data.kpi.goal);
          console.log(data.kpi);
      }
    }, [data]);

    if (!data) {
        // If data or any of its properties are null, return null or display an error message
        return null; // or <div>Error: Missing data</div>
    } 

    const roundToSigFigs = (num, sigFigs) => {
      if (num === 0) return 0;
      const multiplier = Math.pow(10, sigFigs - Math.floor(Math.log10(Math.abs(num))) - 1);
      return Math.round(num * multiplier) / multiplier;
    };

    const formatNumber = (number) => {
      if (Math.abs(number) >= 1000) {
          return new Intl.NumberFormat().format(number);
      } else {
          if (Number.isInteger(number)) {
              return number.toFixed(0).toLocaleString();
          } else {
            if (Math.abs(number) < 10) {
              const rounded = roundToSigFigs(number, 2);
              return rounded.toLocaleString();
            }
            return number.toLocaleString();
          } 
      }
    };

    const formatPercent = (number) => {
      const rounded = roundToSigFigs(number, 2); 
      if (Number.isInteger(rounded)) {
          return number.toFixed(0) + '%';
      } else {
          return rounded + '%'; 
      }
    };

    return (
        <Card className="mx-auto max-w-full h-full">
          {data.name && showname && <h3><b> {data.name} </b></h3>}
          <h4 className="text-lightgrey"> {data.kpi.timeframe} </h4>
          <p className="text-3xl">
            {formatNumber(data.kpi.value)}
          </p>
          <div className={`${(goal === null) ? 'hidden' : 'display'}`}>
            <p className="mt-4 flex items-center justify-between">
              <span>{formatPercent(progress)} of target</span>
              {/* <span>{formatNumber(goal)}</span> */}
              <span>{goal}</span>
            </p>
            {/* <ProgressBar value={progress} color={`${color}`} className={`mt-2 text-[${color}] bg-[${color}]/20`} /> */}
            {/* <ProgressBar value={progress} color={color} style={{ color: color, backgroundColor: `${color}20`, borderColor: color }} className={`mt-2`} /> */}
            {/* <ProgressBar value = {progress} color='lightgrey' className='mt-2' /> */}
            <div className='w-full rounded-full h-2.5 mt-2' style={{backgroundColor: `${color}20`}}>
              <div className='h-2.5 rounded-full' style={{width: `${progress}%`, backgroundColor: color}}></div>
            </div>
          </div>
          {/* {(data.kpi.data.length >= 2) && <div className={`mt-10 stroke-[${color}]`}> */}
          {(data.kpi.data.length >= 2) && <div className='mt-10' style={{ stroke: color, color: color }}>
            <SparkAreaChart
              data={data.kpi.data}
              categories={[columns[1]]}
              index={columns[0]}
              colors={[color]}
              className="w-full"
            />
          </div>}
        </Card>
      );
    
};

export default KeyResult;
