import NavBar from '../components/nav';
import { useState } from 'react'
import axios from 'axios';

function Waitlist() {
    const [email, setEmail] = useState('');
    const [first, setFirst] = useState('');
    const [last, setLast] = useState('');
    const [waitlistSuccess, setWaitlistSuccess] = useState(false);
    const [submitMessage, setSubmitMessage] = useState('');

    const handleEmailChange = (e) => {
        const input = e.target.value;
        setEmail(input);
    };
    const handleFirstChange = (e) => {
        const input = e.target.value;
        setFirst(input);
    }
    const handleLastChange = (e) => {
        const input = e.target.value;
        setLast(input);
    }

    const joinWaitlist = async (e) => { 
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/waitlist`, { first_name: first, last_name: last, email: email });
            setWaitlistSuccess(true);
            setSubmitMessage(response.data.message);
            setEmail('');
            setFirst('');
            setLast('');
    
        } catch (error) {
            console.error('Error adding to waitlist', error);
            setWaitlistSuccess(false);
            setSubmitMessage('Something went wrong. Please try again.');
        }
    };

    return (
        <>
            <NavBar />
            <form className="flex flex-col items-center justify-center h-dvh p-10" onSubmit={joinWaitlist}>
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-md">
                    <div className="sm:col-span-6 text-center mb-8">
                        <h1 className="text-3xl font-semibold">Join our waitlist</h1>
                        <p className="mt-5 text-pretty">Stay informed about lyze.ai release updates and be the first to test out the product when it's ready!</p>
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6"> First name </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                value={first}
                                onChange={handleFirstChange}
                                pattern="^[^\d\{\}\/\\\(\)\*\&\^\%\$\#\@\!\~\`\[\],\.\<\>\?\\'\:;\+\=\-]+$"
                                className="block w-full rounded-md py-3 px-3 shadow-md ring-1 ring-gray-100 focus:ring-2 focus:ring-primary text-sm sm:leading-6 focus:border-primary"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-3">
                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 "> Last name </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                autoComplete="family-name"
                                value={last}
                                onChange={handleLastChange}
                                pattern="^[^\d\{\}\/\\\(\)\*\&\^\%\$\#\@\!\~\`\[\],\.\<\>\?\\'\:;\+\=\-]+$"
                                className="block w-full rounded-md py-3 px-3 shadow-md ring-1 ring-gray-100 focus:ring-2 focus:ring-primary text-sm sm:leading-6 focus:border-primary"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 "> Email address </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                value={email}
                                onChange={handleEmailChange}
                                required
                                className="block w-full rounded-md py-3 px-3 shadow-md ring-1 ring-gray-100 focus:ring-2 focus:ring-primary text-sm sm:leading-6 focus:border-primary"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <button type="submit"
                            className='block w-full relative overflow-hidden bg-primary text-white font-semibold py-3 rounded shadow transition-all hover:bg-gradient-to-r hover:from-primary hover:to-secondary'>
                            Sign up
                        </button>
                        {submitMessage && <span className={`${waitlistSuccess ? 'text-green-500' : 'text-red-500'} text-sm`}>{submitMessage}</span>}
                    </div>
                </div>
            </form>
        </>
    );
}

export default Waitlist;
