import NavBar from '../components/dashboard-nav';
import illustration_1 from '../assets/282-Searching-For-Answers.png'
import ChatBot from './chat';

function AnalyzePage() {
  return (
    <div className = 'flex flex-col justify-center items-center max-h-dvh'>
      <NavBar />
      <div className=" mt-20 p-20 max-w-5xl flex flex-col gap-x-6 gap-y-10 sm:gap-y-20 justify-center items-center">
        <div className='flex flex-col gap-y-10 justify-center items-center'>
            <h1 className='text-4xl text-center'> Get answers, instantly </h1>
            <p className='text-center'> Ask questions about your data for a direct answer, or ask us to generate any charts, graphs or tables to visualize your data</p>
        </div>
        {/* REMOVE THIS SECTION AFTER ENABLING SAMPLE PROMPTS */}
        <div className='flex flex-col justify-center items-center'> 
          <img src={illustration_1} alt='Searching for Answers illustration' className='w-full sm:w-4/5' />
        </div>
        {/* TODO: SAMPLE PROMPTS SECTION */}
        {/* <div className='flex flex-col gap-y-2 justify-center items-center'>
            <h1 className='text-2xl'> Sample prompts </h1>
            <button className='rounded-lg sm:rounded-full border border-lightprimary bg-lightprimary shadow hover:border hover:border-primary hover:text-primary px-5 sm:px-20 py-2 text-sm'>Create a line graph showing daily revenues over time</button>
            <button className='rounded-lg sm:rounded-full border border-lightprimary bg-lightprimary shadow hover:border hover:border-primary hover:text-primary px-5 sm:px-20 py-2 text-sm'>How many customers did I have last month?</button>
            <button className='rounded-lg sm:rounded-full border border-lightprimary bg-lightprimary shadow hover:border hover:border-primary hover:text-primary px-5 sm:px-20 py-2 text-sm'>Create a chart that compares the revenues of each product category over the past year</button>
        </div>  */}
      </div>
      <div className='fixed z-1 w-full bottom-0 max-h-dvh'>
        < ChatBot />
      </div>
    </div>
  );
}

export default AnalyzePage;
