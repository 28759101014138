import ChartHelper from '../charts/chart-helper';
import axios from 'axios';
import SaveMessage from '../components/save-message';
import { useState } from 'react';
import InputBox from '../components/input-box';
import { supabase_userid } from '../utils/supabase-utils';
import SelectGroup from '../components/select-group';
import useTeamInfo from '../utils/get-team';

function Message ({ message }) {
    const { teamId, dashboards, teamLoading } = useTeamInfo();
    const [messageVisible, setMessageVisible] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Unknown Error');
    const [showKpiPopup, setShowKpiPopup] = useState(false);
    const [inputGoal, setInputGoal] = useState(null);
    const [saveToVisible, setSaveToVisible] = useState(false);
    const isKpi = (message.params && message.params.chart_type === 'kpi')

    const saveDashboard = async (destination) => {
      setShowKpiPopup(false);
      setSaveToVisible(false);
      if (isKpi) {
        message.params.kpi.goal = inputGoal;
      }      
      try {
        // call backend saving API
        const userId = await supabase_userid;
        await axios.post(`${process.env.REACT_APP_API_URL}/api/charts`, { state: message.params, sql: message.sql, dashboard: destination.id }, { headers: {
          'X-User-ID': userId,
          ...(teamId && { 'X-Team-ID': teamId })
        }});
        setSuccess(true);

      } catch (error) {
        console.error('Error sending message:', error);
        setSuccess(false);
        setErrorMessage(error.response.data.error);
      } finally {
        setMessageVisible(true);
      }
    };

    const handleCloseMessage = () => {
      setMessageVisible(false);
    };

    // SAVE DASHBOARD
    const handleShowSaveTo = () => {
      setShowKpiPopup(false);
      setSaveToVisible(true);
    };
    const handleSaveToClose = () => {
      setSaveToVisible(false);
    };

    // SAVE KPI
    const handleSaveKpi = () => {
      setShowKpiPopup(true);
    };
    const handleKpiClose = () => {
      setInputGoal(null);
      handleShowSaveTo();
    };
    const handleGoalInput = (input) => {
      setInputGoal(input);
    };


    const isUser = message.sender === 'user';
    if (isUser) {
      return (
        <div className={`flex justify-end mb-2 ml-10`}>
          <div className={'max-w-md px-4 py-2 rounded-lg bg-primary text-white'}>
            {message.text}
            <p className='text-xs'> {message.subtext} </p>
          </div>
        </div>
      );
    }

    if (message.params === null) {
      return (
        <div className={`flex justify-start mb-2 mr-10`}>
          <div className={'max-w-6xl px-4 py-2 rounded-lg bg-lightgrey text-white'}>
            {message.text}
          </div>
        </div>
      );
    }

    const isNumberInput = (value) => {
      if (value === '') { return true; }
      return /^[0-9]+(\.[0-9]+)?$/.test(value);
    }


    return (
      <>
        {showKpiPopup && <InputBox onClose={handleKpiClose} onSubmit={handleShowSaveTo} onUserInput={handleGoalInput} title='Do you want to set a goal?' negativeCta='Skip' positiveCta='Set Goal' startValue={null} inputValidation={isNumberInput} />}
        {saveToVisible && !teamLoading && <SelectGroup onClose={handleSaveToClose} onSubmit={saveDashboard} title='Select dashboard' negativeCta='Cancel' positiveCta='Save' selections={dashboards} />}
        <div className={`flex justify-start mb-2 mr-10`}>
          <div className={'max-w-6xl px-4 py-2 rounded-lg bg-lightgrey text-white'}>
            {message.text}
          </div>
        </div>
          {!isKpi && <div className="w-full sm:w-3/4 h-1/2">
            <ChartHelper params={message.params} showname={true} />
          </div>}
          {messageVisible && <SaveMessage success={success} onClose={handleCloseMessage} errorMessage={errorMessage} />}
          <div className={`flex justify-start mb-2`}>
            <button className={`${isKpi ? 'hidden' : 'flex'} mt-1 px-4 py-2 text-sm rounded-lg border border-secondary text-secondary transition-all duration-200 ease-in-out hover:bg-gradient-to-r hover:from-primary hover:to-secondary hover:text-white hover:border-white`}
              onClick={handleShowSaveTo}>
                {teamLoading ? 'Loading...' : 'Save to dashboard'}
            </button>
            <button className={`${isKpi ? 'flex' : 'hidden'} mt-1 px-4 py-2 text-sm rounded-lg border border-secondary text-secondary transition-all duration-200 ease-in-out hover:bg-gradient-to-r hover:from-primary hover:to-secondary hover:text-white hover:border-white`}
              onClick={handleSaveKpi}>
                Create new KPI
            </button>
          </div>
      </>
    );
  };

export default Message;