import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import NavBar from '../components/dashboard-nav';
import BasicChart from '../charts/basic-chart';
import Dropdown from '../components/dropdown';
import { PlusCircle } from 'react-feather';
import { supabase_userid } from '../utils/supabase-utils';
import useTeamInfo from '../utils/get-team';
import ValidationMessage from '../components/validation-message';
import { chartTypes, filterTypes, fieldTypes, propertyTypes, chartTypeMap } from '../types/types.js'


function ChartEdit() {
    const { teamId, teamLoading } = useTeamInfo();
    const { id } = useParams();
    const [chart, setChart] = useState({});
    const [showEdit, setShowEdit] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveValidationVisible, setSaveValidationVisible] = useState(false);
    const [saveErrorMessage, setSaveErrorMessage] = useState('Unknown Error');
    const [nameValue, setNameValue] = useState('');
    const [selectedChartType, setSelectedChartType] = useState(chartTypes[0])
    const [filterType, setFilterType] = useState(filterTypes[0])
    const [fieldType, setFieldType] = useState(fieldTypes[0])
    const [propertyType, setPropertyType] = useState(propertyTypes[0])
    // const [newName, setNewName] = useState('');
    

    useEffect(() => {
        const fetchChart = async () => {
            try {
                const userId = await supabase_userid;
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/charts/${id}`, {
                    headers: {
                      'X-User-ID': userId,
                      ...(teamId && { 'X-Team-ID': teamId })
                    }
                  });
                if (response && response.data) {
                    setChart(response.data.chart);
                    setShowEdit(true);
                    setNameValue(response.data.chart.name);
                    if (response.data.chart.special_chart_type === '') {
                        setSelectedChartType({item: chartTypeMap[response.data.chart.chart_type]});
                    } else {
                        setSelectedChartType({item: chartTypeMap[response.data.chart.special_chart_type]});
                    };

                } else {
                    console.error("Empty response");
                }
            } catch (error) {
                console.error('Error fetching chart data:', error);
            }
        };
        if (!teamLoading) {
            fetchChart();
        }
    }, [id, teamId, teamLoading]);

    const saveChanges = async () => { 
        try {
        //   await axios.put(`${process.env.REACT_APP_API_URL}/api/charts/${chart.id}`, { name: newName });
          setSaveSuccess(true);
          setSaveValidationVisible(true);
  
        } catch (error) {
          console.error('Error refreshing item:', error);
          setSaveSuccess(false);
          setSaveErrorMessage(error.response.data.error);
          setSaveValidationVisible(true);
        } 
    };

    // const handleNameInput = (input) => {
    //     setNewName(input);
    //   };

    const closeValidationMessage = () => {
        setSaveValidationVisible(false);
        if (saveSuccess) {
            // navigate back to /dashboard
        } 
      };
    
      if (!showEdit) {
        return (
            <>
                < NavBar />
                <div className="mt-20 p-10 sm:p-20">Loading...</div>
            </>
        );
      };

      // HANDLE NAME CHANGES
    const handleChange = (e) => {
        const input = e.target.value;
        setNameValue(input);
    };


    // HANDLE CHART TYPE UPDATE
    const handleChartTypeChange = async (newChartType) => {
        // update rendered graph
        const key = Object.keys(chartTypeMap).find(key => chartTypeMap[key] === newChartType.item)
        if (['stack', 'stack_100'].includes(key)) {
            setChart(prevChart => ({
                ...prevChart,
                chart_type: 'bar',
                special_chart_type: key
            }));
        } else {
            setChart(prevChart => ({
                ...prevChart,
                chart_type: key,
                special_chart_type: ''
            }));
        }
        
        
        console.log('Selected field type:', newChartType);
        setSelectedChartType(newChartType); // Update the state with the new field type
    };



   

    return (
        <>
            < NavBar />
            {saveValidationVisible && <ValidationMessage success={saveSuccess} onClose={closeValidationMessage} errorMessage={saveErrorMessage} type="Save" />}
            <div className='h-dvh p-10 pt-20 sm:p-20 sm:pt-32 flex flex-col gap-y-5'> 
                <div className='w-full flex flex-col sm:flex-row h-full min-h-fit'>
                    <div className='flex w-full sm:w-1/2 min-h-96'>
                        <BasicChart chartType={chart.chart_type} data={chart} showname={false} />
                    </div>
                    <div id='edit-fields' className='flex w-full flex-col space-y-5 sm:w-1/2 sm:pl-20 whitespace-nowrap'>
                        <div id = 'name' className='flex flex-col w-full space-y-1'>
                            <h1> Name </h1>
                            <input type="text" value={nameValue} onChange={handleChange} className="w-full text-2xl sm:text-3xl rounded-md py-1.5 px-3 ring-1 ring-inset ring-gray-300 h-fit" />
                        </div>
                        <div id = 'chart-type' className='flex flex-col w-full space-y-1'>
                            <h1> Chart type </h1>
                            <Dropdown defaultValue={selectedChartType} selection={chartTypes} onChange={handleChartTypeChange} width={'w-full sm:w-72 '} height={'max-h-40'} fontSize={'text-base'} />
                        </div>
                        <div id = 'filter' className='flex flex-col w-full space-y-1'>
                            <h1> Filters </h1>
                            <div className='flex sm:flex-row flex-col flex-wrap sm:space-x-2 items-center'>
                                <Dropdown defaultValue={fieldType} selection={fieldTypes} onChange={setFieldType} width={'w-full sm:w-72 '} height={'max-h-40'} fontSize={'text-base'} />
                                <Dropdown defaultValue={filterType} selection={filterTypes} onChange={setFilterType} width={'w-full sm:w-52 '} height={'max-h-40'} fontSize={'text-base'} />
                                <Dropdown defaultValue={propertyType} selection={propertyTypes} onChange={setPropertyType} width={'w-full sm:w-96 '} height={'max-h-40'} fontSize={'text-base'} />
                                <PlusCircle className='text-gray-300'/>
                            </div>
                        </div>
                        <div id = 'sort' className='flex flex-col w-full space-y-1'>
                            <h1> Sort </h1>
                            <div className='flex flex-row space-x-2'>
                                <button className="flex bg-white py-2 px-5 h-fit rounded shadow min-w-fit w-1/2 sm:w-24 justify-center"> None </button>
                                <button className="flex bg-white py-2 px-5 h-fit rounded shadow min-w-fit w-1/2 sm:w-24 justify-center"> Asc </button>
                                <button className="flex bg-white py-2 px-5 h-fit rounded shadow min-w-fit w-1/2 sm:w-24 justify-center"> Desc </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:justify-end sm:space-x-2'>
                    <button className="flex items-center justify-center bg-white py-2 px-10 h-fit rounded shadow w-full sm:w-fit"><Link to="/dashboard"> Cancel </Link></button>
                    <button className="flex items-center justify-center bg-primary text-white py-2 px-10 h-fit rounded shadow w-full sm:w-fit whitespace-nowrap" onClick={saveChanges}> Save Changes </button>    
                </div>
            </div>
        </>
    );
}

export default ChartEdit;
