import NavBar from '../components/dashboard-nav';
import DashboardItem from './dashboard-item';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { supabase_userid } from '../utils/supabase-utils';
import { Link } from 'react-router-dom'
import { Listbox } from '@headlessui/react'
import useTeamInfo from '../utils/get-team';
import useColors from '../utils/get-colors';
import { ChevronDown } from 'react-feather'


function Dashboard() {
  const { primary, secondary, colorLoading } = useColors();
  const { teamId, dashboards, teamLoading } = useTeamInfo();
  const [charts, setCharts] = useState([]);
  const [kpis, setKpis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(dashboards[0]);

  const fetchCharts = useCallback(async () => {
    try {
        const userId = await supabase_userid;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/load-dashboard`, {
          params: { dashboard: selected.id },
          headers: {
            'X-User-ID': userId,
            ...(teamId && { 'X-Team-ID': teamId })
          }
        });
        setCharts(response.data.charts);
        setKpis(response.data.kpis);
        setLoading(false);
    } catch (error) {
        console.error('Error fetching files:', error);
    }
  }, [selected.id, teamId]);

  useEffect(() => {
    if (!teamLoading && !colorLoading) {
      fetchCharts();
    }
  }, [selected, teamLoading, colorLoading, fetchCharts]);


  const handleReload = async () => {
    fetchCharts();
  };

  function Render() {
    if (loading) {
      return <div>Loading...</div>;
    }  
    else if ((charts.length + kpis.length) === 0) {
        return <div>Nothing in your dashboard. <Link to="/analyze" className='text-primary underline'>Start analyzing now!</Link></div>;
      }
    return ( 
      <div className='flex flex-col w-full'>
        <div className='max-w-full h-fit flex flex-row flex-wrap'>
          {kpis.map((chart) => (
            <DashboardItem chart={chart} onReload={handleReload} teamId={teamId} colors={{ primary: primary, secondary: secondary }} />
          ))}
        </div>
        <div className='max-w-full h-fit flex flex-row flex-wrap'>
          {charts.map((chart) => (
            <DashboardItem chart={chart} onReload={handleReload} teamId={teamId} colors={{ primary: primary, secondary: secondary }} />
          ))}
        </div>
      </div>
    );
  };


  return (
    <>
      <NavBar />
      <div className="mt-20 px-5 pt-10 sm:pt-20 pb-10 sm:p-20 sm:pb-10">
        <Listbox value={selected} onChange={setSelected} className='w-fit my-5'>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-fit min-w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 shadow text-sm">
              <span className="block">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                < ChevronDown size={15} />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute mt-1 w-fit min-w-full rounded-md bg-white py-1 text-sm shadow">
              {dashboards.map((item) => (
                <Listbox.Option key={item.id}
                  className={({ active }) => `relative cursor-default select-none py-2 pl-3 pr-10 ${ active ? 'bg-lightprimary' : 'bg-white' }`}
                  value={item}
                >
                  {({ selected }) => (
                    <span className={`block ${ selected ? 'font-medium' : 'font-normal' }`} >
                      {item.name}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
        <h1 className="text-4xl"> Dashboard </h1>
      </div>
      {/* THIS PART IS ALL HARD-CODED. NOT ACTUALLY FUNCTIONAL. TODO// */}
      {/* <div className='w-full px-5 sm:pl-20 sm:pr-20 mb-10'>
        <div className='w-full p-5 bg-lightprimary rounded-lg'>
          <p><b> 💡 AI Insights 💡 </b></p>
          <ul className='list-disc list-inside'>
            <li>You had a spike in revenues on February 8th even though your transaction count seems average, driven by trade_id 238742. If this is unexpected, please verify this data. </li>
            <li>The share of electronics sales in overall sales has been increasing month on month for the past 3 months. This seems to be an increasingly dominant product category.</li>
            <li>The share of users in Gold status shrunk by 10% since last month. Let's dig into <a href='' className='text-primary underline'>why</a>. </li>
          </ul>
        </div>
      </div> */}
      {/* END OF HARD-CODED STUFF */}
      <div className='px-5 sm:px-20 mb-20 flex min-h-fit'>
        < Render />
      </div>
    </>
  );
}

export default Dashboard;
