import NavBar from '../components/nav';
import { useState } from 'react'
import {Link} from 'react-router-dom'
import { supabase } from '../utils/supabaseClient';

function Signup() {
    const [formData, setFormData] = useState({ first: '', last: '', email: '', password: '' });
    const [submitMessage, setSubmitMessage] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { first, last, email, password } = formData;
            const { error } = await supabase.auth.signUp(
                {
                    email: email,
                    password: password,
                    options: {
                      data: {
                        first_name: first,
                        last_name: last,
                      }
                    }
                  }
                );
            if (error) throw error;
            setSuccess(true);
            setSubmitMessage('Successfully created account! Please check your email to verify your email address.');
            setFormData({ first: '', last: '', email: '', password: '' });
        } catch (error) {
            console.error('Error:', error);
            setSuccess(false);
            setSubmitMessage(error.message);
            setFormData({ first: '', last: '', email: '', password: '' });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <>
            <NavBar />
            <form className='flex flex-col items-center justify-center h-dvh p-10' onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6 max-w-md">
                    <div className="sm:col-span-6 text-center">
                        <h1 className="text-3xl font-semibold">Create an account</h1>
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="first" className="block text-sm font-medium leading-6"> First name </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="first"
                                id="first"
                                autoComplete="given-name"
                                value={formData.first}
                                onChange={handleChange}
                                pattern="^[^\d\{\}\/\\\(\)\*\&\^\%\$\#\@\!\~\`\[\],\.\<\>\?\\'\:;\+\=\-]+$"
                                className="block w-full rounded-md py-3 px-3 shadow-md ring-1 ring-gray-100 focus:ring-2 focus:ring-primary text-sm sm:leading-6 focus:border-primary"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="last" className="block text-sm font-medium leading-6 "> Last name </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="last"
                                id="last"
                                autoComplete="family-name"
                                value={formData.last}
                                onChange={handleChange}
                                pattern="^[^\d\{\}\/\\\(\)\*\&\^\%\$\#\@\!\~\`\[\],\.\<\>\?\\'\:;\+\=\-]+$"
                                className="block w-full rounded-md py-3 px-3 shadow-md ring-1 ring-gray-100 focus:ring-2 focus:ring-primary text-sm sm:leading-6 focus:border-primary"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-6">
                        <label htmlFor="email" className="block text-sm font-medium leading-6"> Email address </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="block w-full rounded-md py-3 px-3 shadow-md ring-1 ring-gray-100 focus:ring-2 focus:ring-primary text-sm sm:leading-6 focus:border-primary"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-6">
                        <label htmlFor="password" className="block text-sm font-medium leading-6 "> Password </label>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                className="block w-full rounded-md py-3 px-3 shadow-md ring-1 ring-gray-100 focus:ring-2 focus:ring-primary text-sm sm:leading-6 focus:border-primary"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-6">
                        <button type="submit"
                            className='block w-full relative overflow-hidden bg-primary text-white font-semibold py-3 rounded shadow transition-all hover:bg-gradient-to-r hover:from-primary hover:to-secondary'>
                            Create Account
                        </button>
                        {submitMessage && <span className={`${success ? 'text-green-500' : 'text-red-500'} text-sm`}>{submitMessage}</span>}
                    </div>
                    <div className='sm:col-span-6'>
                        <Link to="/login" className="text-xs underline text-secondary">Already have an account? Log in here</Link>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Signup;
