import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import NavBar from '../components/dashboard-nav';
import { supabase_userid } from '../utils/supabase-utils';
import { X } from 'react-feather';
import ValidationMessage from '../components/validation-message';
import useTeamInfo from '../utils/get-team';


function DataDictionary() {
    const { teamId, teamLoading } = useTeamInfo();
    const { fileId } = useParams();
    const [submitMessage, setSubmitMessage] = useState('');
    const [validationVisible, setValidationVisible] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fileName, setFileName] = useState('');
    const [columns, setColumns] = useState([]);
    const [formData, setFormData] = useState({});
    const [userId, setUserId] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const getUserId = async () => {
            const id = await supabase_userid;
            setUserId(id);
        };
        getUserId();
    }, []);

    useEffect(() => {
        if (userId === null) return; 
        const fetchFileData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/files/${fileId}/dictionary`, {
                    headers: {
                      'X-User-ID': userId,
                      ...(teamId && { 'X-Team-ID': teamId })
                    }
                  });
                if (response && response.data) {
                    const { filename, table, columns } = response.data;
                    setColumns(columns);
                    setFileName(filename);
                    setFormData(Object.assign({ 'description': table }, columns));
                } else {
                    console.error("Empty response")
                }
            } catch (error) {
                console.error('Error fetching file data:', error);
            }
        };
        fetchFileData();
    }, [teamLoading, fileId, teamId, userId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/files/${fileId}/dictionary`, formData, {
                headers: {
                  'X-User-ID': userId,
                  ...(teamId && { 'X-Team-ID': teamId })
                }
              });
            setSuccess(true);
        } catch (error) {
            console.error('Error:', error);
            setSuccess(false);
            setSubmitMessage(error.response.data.error);
        } finally {
            setValidationVisible(true);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCloseValidationMessage = () => {
        setValidationVisible(false);
        if (success) {
            navigate('/data');
        }
      };
   

    return (
        <div className="mt-20 p-10 sm:p-20 flex flex-col gap-y-5">
            < NavBar />
            {validationVisible && <ValidationMessage success={success} onClose={handleCloseValidationMessage} errorMessage={submitMessage} type="Update" />}
            <div className='flex flex-row justify-between gap-x-8'>
                <div className='flex flex-col sm:flex-row gap-x-8 text-wrap'>
                    <h1 className="text-3xl sm:text-4xl pb-10"> {fileName} </h1>
                </div>
                <button className="flex items-center h-fit p-x-2"><Link to="/data"> < X size={40} /> </Link></button>    
            </div>
            <form onSubmit={handleSubmit} className="space-y-4 sm:max-w-3xl">
                <div className='text-lg font-semibold p-2 w-full text-center bg-gray-300 rounded-md'>General</div>
                <div className='flex flex-col sm:flex-row pb-10'>
                    <label htmlFor="description" className="w-full whitespace-nowrap sm:w-56 sm:text-end sm:pr-5 text-wrap">Data description</label>
                    <input
                        type="text"
                        id="description"
                        name="description"
                        value={formData['description']}
                        onChange={handleChange}
                        className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-primary"
                    />
                </div>
                <div className='text-lg font-semibold p-2 w-full text-center bg-gray-300 rounded-md'>Columns</div>
                {/* {columns.map((column, index) => (
                    <div key={index} className='flex flex-col sm:flex-row'>
                        <label htmlFor={column} className="w-full whitespace-nowrap sm:w-56 sm:text-end sm:pr-5 text-wrap">{column}</label>
                        <input
                            type="text"
                            id={column}
                            name={column}
                            value={formData[column]}
                            onChange={handleChange}
                            className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-primary"
                        />
                    </div>
                ))} */}
                {Object.entries(columns).map(([column, description], index) => (
                    <div key={index} className='flex flex-col sm:flex-row'>
                        <label htmlFor={column} className="w-full whitespace-nowrap sm:w-56 sm:text-end sm:pr-5 text-wrap">{column}</label>
                        <input
                            type="text"
                            id={column}
                            name={column}
                            value={formData[column]}
                            onChange={handleChange}
                            className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-primary"
                        />
                    </div>
                ))}
                <div className='w-full flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:justify-end sm:space-x-2'>
                    <button className="flex items-center justify-center bg-white py-2 px-10 h-fit rounded shadow w-full sm:w-fit" type='button'><Link to="/data"> Cancel </Link></button>
                    <button className="flex items-center justify-center bg-primary text-white py-2 px-10 h-fit rounded shadow w-full sm:w-fit whitespace-nowrap" type="submit"> Save Changes </button>    
                </div>
            </form>
        </div>
    );
}

export default DataDictionary;
