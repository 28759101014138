import { useState, useEffect } from 'react';
import axios from 'axios';
import { supabase_userid } from '../utils/supabase-utils';

const useTeamInfo = () => {
  const [teamId, setTeamId] = useState(null);
  const [teamName, setTeamName] = useState(null);
  const [dashboards, setDashboards] = useState([{id: 1, name: 'Personal'}]);
  const [teamLoading, setTeamLoading] = useState(true);

  useEffect(() => {
    const fetchTeamInfo = async () => {
      try {
        const userId = await supabase_userid;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/team`, {
          headers: {
            'X-User-ID': userId,
          },
        });
        if (response.data.team_id) {
          setTeamName(response.data.team_name);
          setTeamId(response.data.team_id); 
          setDashboards([ { id: 1, name: 'Personal' }, { id: 2, name: 'Team' },]);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setTeamLoading(false);
      }
    };
    fetchTeamInfo();
  }, []);

  return { teamId, teamName, dashboards, teamLoading };
};

export default useTeamInfo;
