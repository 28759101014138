import React from 'react';
import Chart from 'react-apexcharts';

const BasicChart = ({ chartType, data, showname, colors = { primary: '#43B4F9', secondary: '#A945FC' } }) => {
    if (!data) {
        // If data or any of its properties are null, return null or display an error message
        return null; // or <div>Error: Missing data</div>
    }
    const defaultColorsList = ['#43B4F9', '#A945FC', '#FEC241', '#91F559', '#546E7A', '#1F87F1', '#F778A3', '#F6A463', '#4CBF62', '#754BEE']
    const colorsSet = new Set([colors.primary.toUpperCase(), colors.secondary.toUpperCase(), ...defaultColorsList]);
    const colorsList = Array.from(colorsSet);

    const first_axis = data['y'][0]['unit'] 
    const uniqueUnits = new Set();
    data['y'].forEach(item => {
        uniqueUnits.add(item.unit);
      });
    const uniqueUnitArray = Array.from(uniqueUnits);
    const yAxisUnits = uniqueUnitArray.map(unit => ({
        title: { text: unit }
    }));

    // y-axis values
    let series;
    if (['donut', 'pie'].includes(chartType)) {
        series = data['y'][0]['data'];
    } else {
        series = data['y'].map(item => ({
            name: item['name'],
            type: item['type'],
            data: item['data'],
            yAxis: (item['unit'] === first_axis) ? 'y1' : 'y2' // currently don't support more than 2 y-axis
        }));
    }

    // Options for the chart
    const options = {
        chart: {
            id: data['name'],
            toolbar: {show: true},
            stacked: ['stack', 'stack_100'].includes(data.special_chart_type) ? true : false,
            stackType: ['stack_100'].includes(data.special_chart_type) ? '100%' : 'normal',
            background: 'white',
            fontFamily: 'inherit',
        },
        xaxis: {
            categories: data['x'][0]['data'],
            title: { text: data['x'][0]['unit'] },
            labels: { rotate:-45},
            tooltip: {enabled: false}
        },
        yaxis: {
            show: true,
            title: { text: data['y'][0]['unit'] },
            forceNiceScale: true,
            labels: {
                formatter: function (value) {
                    if (Number.isInteger(value) || typeof value === 'number') {
                    // Format the value with thousands separator
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else {
                        return value;
                  }
                }
              },
            units: yAxisUnits
        },
        dataLabels: {
            enabled: (data['x'][0]['data'].length > 5) ? false : true,
            formatter: function (value) {
                if (['donut', 'pie'].includes(chartType)) {
                    return Number((value).toFixed(2)) + "%"
                }
                else if (['stack_100'].includes(data.special_chart_type)) {
                    return Number((value).toFixed(2)) + "%"
                }
                else if (Number.isInteger(value) || typeof value === 'number') {
                // Format the value with thousands separator
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return value;
                }
            }
        },
        title: {
            text: showname ? data['name'] : '',
        },
        colors: colorsList,
        labels: data['x'][0]['data'],
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: (chartType === 'donut') ? true : false,
                        formatter: function (value) {
                            if (Number.isInteger(value) || typeof value === 'number') {
                            // Format the value with thousands separator
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return value;
                          }
                        },
                    },
                }
            },
            bar: {
                distributed: false, // true for each bar to be different colors
            }
        }
        
    };

    return (
        <div className="w-full h-full">
            <Chart
                key={chartType}
                options={options} 
                series={series}
                type={chartType}
                height="100%"
                width="100%"
            />
        </div>
    );
};

export default BasicChart;
