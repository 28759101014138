import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import {Link} from 'react-router-dom'

function SaveMessage ({ success, onClose, errorMessage }) {

    const open = true
    return (

        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50"  onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${success ? 'bg-green-100' : 'bg-red-100'} sm:mx-0 sm:h-10 sm:w-10`}>
                                    { success ? <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> : <XCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                                        {success ? 'Saved successfully!' : 'Error saving to dashboard'}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                    <p className="text-sm text-lightgrey">
                                        {success ? '' : errorMessage}
                                    </p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 flex flex-col sm:flex-row sm:justify-end sm:px-6 space-y-2 sm:space-y-0 sm:space-x-2">
                                <button
                                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:w-auto"
                                    onClick={onClose}
                                >
                                    Close
                                </button>
                                {success && <Link 
                                    to="/dashboard" 
                                    className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm sm:w-auto"
                                    onClick={onClose}
                                >
                                    Go to Dashboard
                                </Link>}
                                
                            </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
  };

  export default SaveMessage;