import { supabase } from "./supabaseClient";

const getUserId = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    return user ? user.id : null;
};

// const { data: { user } } = await supabase.auth.getUser();
// export const supabase_userid = user ? user.id : null;
export const supabase_userid = getUserId();
