import { supabase_userid } from '../utils/supabase-utils';
import NavBar from '../components/dashboard-nav';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import InputBox from '../components/input-box';
import { isStringInput } from '../utils/utils';
import ValidationMessage from '../components/validation-message';
import ConfirmAction from '../components/confirm-action';
import { BlockPicker } from 'react-color';
import useColors from '../utils/get-colors';
import { Divider } from '@tremor/react';


function Settings() {
    const [userId, setUserId] = useState(null);
    const { primary, secondary, colorLoading } = useColors();
    const [teamName, setTeamName] = useState(null);
    const [isInTeam, setIsInTeam] = useState(false);
    const [inputTeamName, setInputTeamName] = useState(null);
    // JOIN A TEAM
    const [joinTeamVisible, setJoinTeamVisible] = useState(false);
    const [joinTeamSuccess, setJoinTeamSuccess] = useState(false);
    const [joinValidationVisible, setJoinValidationVisible] = useState(false);
    const [joinError, setJoinError] = useState(null);
    // CREATE A TEAM
    const [createTeamVisible, setCreateTeamVisible] = useState(false);
    const [createTeamSuccess, setCreateTeamSuccess] = useState(false);
    const [createValidationVisible, setCreateValidationVisible] = useState(false);
    const [createError, setCreateError] = useState(null);
    // LEAVE TEAM
    const [leaveTeamVisible, setLeaveTeamVisible] = useState(false);
    const [leaveTeamSuccess, setLeaveTeamSuccess] = useState(false);
    const [leaveValidationVisible, setLeaveValidationVisible] = useState(false);
    const [leaveError, setLeaveError] = useState(null);
    // COLORS
    const [primaryColor, setPrimaryColor] = useState('#FFFFF');
    const [secondaryColor, setSecondaryColor] = useState('#FFFFF');
    const [resetPrimaryColor, setResetPrimaryColor] = useState('#FFFFF');
    const [resetSecondaryColor, setResetSecondaryColor] = useState('#FFFFF');
    const [saveColorSuccess, setSaveColorSuccess] = useState(false);
    const [colorValidationVisible, setColorValidationVisible] = useState(false);
    const [colorError, setColorError] = useState(null);

    useEffect(() => {
        const getUserId = async () => {
            const id = await supabase_userid;
            setUserId(id);
        };
        getUserId();
    }, []);

    const getTeam = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/team`, {
              headers: {
                'X-User-ID': userId,
              }
            });
            if (response.data.team_name) {
                setTeamName(response.data.team_name);
                setIsInTeam(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setIsInTeam(false);
        }
    }, [userId]);

    const getColors = useCallback(async () => {
        setPrimaryColor(primary);
        setSecondaryColor(secondary);
        setResetPrimaryColor(primary);
        setResetSecondaryColor(secondary);
    }, [primary, secondary]);

    useEffect(() => {
        if (userId === null) return; 
        getTeam();
        getColors();
    }, [colorLoading, getColors, getTeam, userId]);

    const handleNameInput = (input) => {
        setInputTeamName(input);
    };
    // JOIN A TEAM
    const showJoinTeam = () => {  
        setJoinTeamVisible(true);
    };
    const hideJoinTeam = () => {  
        setJoinTeamVisible(false);
    };
    const hideJoinTeamValidtion = () => {  
        setJoinValidationVisible(false);
        getTeam();
    };
    const joinTeam = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/join-team`, { name: inputTeamName }, { headers: {
                'X-User-ID': userId,
              }});
            setJoinTeamSuccess(true);
        } catch (error) {
            console.error('Error joining team:', error);
            setJoinTeamSuccess(false);
            setJoinError(error.response.data.error);
        } finally {
            setJoinValidationVisible(true);
            setJoinTeamVisible(false);
        }
      };

    // CREATE A TEAM
    const showCreateTeam = () => {  
        setCreateTeamVisible(true);
    };
    const hideCreateTeam = () => {  
        setCreateTeamVisible(false);
    };
    const hideCreateTeamValidtion = () => {  
        setCreateValidationVisible(false);
        getTeam();
    };
    const createTeam = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/create-team`, { name: inputTeamName }, { headers: {
                'X-User-ID': userId,
              }});
            setCreateTeamSuccess(true);
        } catch (error) {
            console.error('Error creating team:', error);
            setCreateTeamSuccess(false);
            setCreateError(error.response.data.error);
        } finally {
            setCreateValidationVisible(true);
            setCreateTeamVisible(false);
        }
      };

    // LEAVE TEAM
    const showLeaveTeam = () => {  
        setLeaveTeamVisible(true);
    };
    const hideLeaveTeam = () => {  
        setLeaveTeamVisible(false);
    };
    const hideLeaveTeamValidtion = () => {  
        setLeaveValidationVisible(false);
        getTeam();
    };
    const leaveTeam = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/join-team`, { headers: {
                'X-User-ID': userId,
              }});
            setLeaveTeamSuccess(true);
        } catch (error) {
            console.error('Error leaving team:', error);
            setLeaveTeamSuccess(false);
            setLeaveError(error.response.data.error);
        } finally {
            setLeaveValidationVisible(true);
            setLeaveTeamVisible(false);
        }
    };

    // COLORS
    const handlePrimaryColor = (color) => {
        setPrimaryColor( color.hex );
    };
    const handleSecondaryColor = (color) => {
        setSecondaryColor( color.hex );
    };
    const handleResetColors = () => {
        setPrimaryColor( resetPrimaryColor );
        setSecondaryColor( resetSecondaryColor );
    };
    const hideColorValidtion = () => {  
        setColorValidationVisible(false);
    };
    const updateColors = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/colors`, { primary: primaryColor, secondary: secondaryColor }, { headers: {
                'X-User-ID': userId,
              }});
              setSaveColorSuccess(true);
              setResetPrimaryColor(primaryColor);
              setResetSecondaryColor(secondaryColor);
        } catch (error) {
            console.error('Error savings colors:', error);
            setSaveColorSuccess(false);
            setColorError(error.response.data.error);
        } finally {
            setColorValidationVisible(true);
        }
    };

    const handleManageSubscription = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/customer-portal`, {}, { headers: {
            'X-User-ID': userId,
          }});
        window.location.href = response.data.url;
    };
    

    return (
        <>
            <NavBar />
            {createTeamVisible && <InputBox onClose={hideCreateTeam} onSubmit={createTeam} onUserInput={handleNameInput} title='Name Your Team' negativeCta='Close' positiveCta='Create' startValue='' inputValidation={isStringInput} />}
            {createValidationVisible && <ValidationMessage success={createTeamSuccess} onClose={hideCreateTeamValidtion} errorMessage={createError} type="Create Team" />}
            {joinTeamVisible && <InputBox onClose={hideJoinTeam} onSubmit={joinTeam} onUserInput={handleNameInput} title='Request to Join Team' negativeCta='Close' positiveCta='Request' startValue='' inputValidation={isStringInput} />}
            {joinValidationVisible && <ValidationMessage success={joinTeamSuccess} onClose={hideJoinTeamValidtion} errorMessage={joinError} type="Join Team" />}
            {leaveTeamVisible && <ConfirmAction onClose={hideLeaveTeam} onConfirm={leaveTeam} confirmType='Leave Team' confirmMessage={`You will no longer be part of ${teamName}`} />}
            {leaveValidationVisible && <ValidationMessage success={leaveTeamSuccess} onClose={hideLeaveTeamValidtion} errorMessage={leaveError} type="Leave Team" />}
            {colorValidationVisible && <ValidationMessage success={saveColorSuccess} onClose={hideColorValidtion} errorMessage={colorError} type="Save Colors" />}
            <div className='flex flex-col mt-20 px-5 sm:px-20 py-10 sm:py-20 gap-y-10'>
                <h1 className="text-4xl mb-10"> Settings </h1>
                <div>
                    <p className='text-xl pb-5'>Subscription</p>
                    <button className='inline-flex w-full justify-center rounded-md bg-primary text-white px-10 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:w-auto' 
                        onClick={handleManageSubscription}>Manage Subscription</button>
                </div>
                <Divider/>
                <div>
                    <p className='text-xl pb-5'>Teams</p>
                    {isInTeam ? (
                        <div className='flex flex-col w-full sm:w-fit gap-y-2'>
                            <p> Your team: <b>{teamName}</b> </p>
                            <button
                                className="inline-flex w-full justify-center rounded-md bg-red-600 text-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:w-auto"
                                onClick={showLeaveTeam} >
                                Leave team
                            </button>
                        </div>
                    ) : 
                    (
                        <div className='flex flex-col w-full sm:flex-row sm:w-auto gap-y-2 sm:gap-x-2'>
                            <button
                                className="inline-flex w-full justify-center rounded-md bg-primary text-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:w-auto"
                                onClick={showJoinTeam} >
                                Join a team
                            </button>
                            <button
                                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:w-auto"
                                onClick={showCreateTeam} >
                                Create a team
                            </button>
                        </div>
                    )}
                </div>
                <Divider/>
                <div className='flex flex-col'>
                    <p className='text-xl'>Customize Dashboard Colors</p>
                    <div className='flex flex-col sm:flex-row'>
                        <div className='flex flex-col gap-y-2 p-5'>
                            <label> Primary color: </label>
                            <BlockPicker color={ primaryColor } onChangeComplete={ handlePrimaryColor } triangle='hide' colors={['#43B4F9', '#A945FC', '#FEC241', '#91F559', '#546E7A']} />
                        </div>
                        <div className='flex flex-col gap-y-2 p-5'>
                            <label> Secondary color: </label>
                            <BlockPicker color={ secondaryColor } onChangeComplete={ handleSecondaryColor } triangle='hide' colors={['#43B4F9', '#A945FC', '#FEC241', '#91F559', '#546E7A']} />
                        </div>
                    </div>
                    <div className='flex flex-col w-full sm:flex-row sm:w-auto gap-y-2 sm:gap-x-2'>
                        <button
                            className="inline-flex w-full justify-center rounded-md bg-primary text-white px-10 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:w-auto"
                            onClick={updateColors} >
                            Save
                        </button>
                        <button
                            className="inline-flex w-full justify-center rounded-md bg-white px-10 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:w-auto"
                            onClick={handleResetColors} >
                            Reset
                        </button>
                    </div>
                </div>
            </div>
            
        
        </>
    );
}

export default Settings;
