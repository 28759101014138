import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useState } from 'react'

function InputBox ({ onClose, onSubmit, onUserInput, title, negativeCta, positiveCta, startValue, inputValidation }) {
    const [inputValue, setInputValue] = useState(startValue);
    const [error, setError] = useState('');
    const open = true;

    const handleChange = (e) => {
        const input = e.target.value;
        setInputValue(input);
        onUserInput(input);

        if (!inputValidation(input)) {
            setError('Invalid input');
        } else {
            setError('');
        }
      };

    return (

        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => {}} static>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                                        {title}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <input type="text" value={inputValue} onChange={handleChange}
                                            className="block w-full text-sm rounded-md border-0 py-1.5 px-3 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary focus:border-primary sm:leading-6" />
                                        {error && <span className='text-red-500 text-sm'>{error}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 flex flex-col sm:flex-row sm:justify-end sm:px-10 space-y-2 sm:space-y-0 sm:space-x-2">
                                <button
                                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold shadow-sm sm:w-auto sm:px-5 ring-1 ring-inset ring-gray-300"
                                    onClick={onClose}
                                >
                                    {negativeCta}
                                </button>
                                <button 
                                    className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm sm:w-auto sm:px-5"
                                    onClick={onSubmit}
                                    disabled={error !== ''}
                                >
                                    {positiveCta}
                                </button>
                                
                            </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
  };

  export default InputBox;