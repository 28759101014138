import NavBar from "../components/dashboard-nav";
import { Link } from 'react-router-dom'

const Success = () => {
    return (
        <>
            <NavBar />
            <div className="flex flex-col items-center justify-center w-full h-dvh gap-y-10">
                <h1 className="text-5xl">🎉 Success!</h1>
                <button className="block relative overflow-hidden bg-primary text-white font-semibold py-3 px-10 rounded shadow text-sm"><Link to="/data">Start exploring</Link></button>
            </div>
        </>
    );
};

export default Success;
