import React, { useState } from 'react';
import axios from 'axios';
import Message from './message';
import { supabase_userid } from '../utils/supabase-utils';
import { Listbox } from '@headlessui/react'
import { ChevronDown } from 'react-feather'
import Modules from './modules';
import { modulesList } from '../types/types';
import useTeamInfo from '../utils/get-team';

function ChatBot() {
  const [messages, setMessages] = useState([]);
  const [module, setModule] = useState(modulesList[0]);
  const { teamId } = useTeamInfo();


  const handleSubmit = async (inputText, subInputText, data) => {
    if (!inputText.trim()) return;
    
    // Add user message to state
    setMessages(prevMessages => [...prevMessages, { text: inputText, subtext: subInputText, params: null, sql: null, sender: 'user' }]);

    try {
      // Send user message to backend
      // temp-message for hard-coded queries, send-message for openai
      const userId = await supabase_userid;
      console.log(messages);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/send-message`, { query: data, conversation: messages }, { headers: {
        'X-User-ID': userId,
        ...(teamId && { 'X-Team-ID': teamId }),
        'Content-Type': 'application/json',
      }});

      // Add system response to state
      // setMessages(prevMessages => [...prevMessages, { text: response.data.text, sender: 'system' }]);
      setMessages(prevMessages => [...prevMessages, { text: response.data.text, subtext: null, params: response.data.params, sql: response.data.sql, sender: 'system' }]);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => [...prevMessages, { text: "Something went wrong, please try again.", subtext: null, params: null, sql: null, sender: 'system' }]);
    }
  };

  return (
    // <div className="flex flex-col">
    <div className={`flex flex-col bg-white pt-28 w-full items-center ${messages.length > 0 ? 'h-dvh' : ''}`}>
      <div className="flex-1 overflow-y-auto w-10/12">
        {messages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
      </div>
      <div className="flex flex-col items-center w-full bg-ultralightgrey pb-2 sm:pb-16">
        {/* Module Dropdown */}
        <div className='w-10/12 p-4 z-10'>
          <Listbox value={module} onChange={setModule} className='w-fit my-5'>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-fit min-w-full cursor-default rounded-lg pl-3 pr-10 text-sm">
                <span className="block font-bold">{module.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  < ChevronDown size={15} />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute mt-1 w-fit min-w-full rounded-md bg-white py-1 text-sm shadow">
                {modulesList.map((item) => (
                  <Listbox.Option key={item.id}
                    className={({ active }) => `relative cursor-default select-none py-2 pl-3 pr-10 ${ active ? 'bg-lightprimary' : 'bg-white' }`}
                    value={item}
                  >
                    {({ selected }) => (
                      <span className={`block ${ selected ? 'font-medium' : 'font-normal' }`} >
                        {item.name}
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>
        </div>
        <div className='w-10/12 px-4'>
          <Modules module={module.name} handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
