import React from 'react';
import ChartHelper from '../charts/chart-helper';
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { Trash, MoreVertical, RefreshCw, Eye, Edit2, Tool, Target } from 'react-feather';
import ShowQuery from '../components/show-query';
import DeleteConfirmMessage from '../components/delete-confirm';
import ValidationMessage from '../components/validation-message';
import axios from 'axios';
import InputBox from '../components/input-box';
import { Link } from 'react-router-dom';
import { supabase_userid } from '../utils/supabase-utils';
import { isStringInput, isNumberInput } from '../utils/utils';

function DashboardItem({ chart, onReload, teamId, colors }) {
    const [userId, setUserId] = useState(null);
    // DELETE
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [deleteValidationVisible, setDeleteValidationVisible] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('Unknown Error');
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    // REFRESH
    const [refreshSuccess, setRefreshSuccess] = useState(false);
    const [refreshValidationVisible, setRefreshValidationVisible] = useState(false);
    const [refreshErrorMessage, setRefreshErrorMessage] = useState('Unknown Error');
    // SHOW QUERY
    const [showQueryVisible, setShowQueryVisible] = useState(false);
    // RENAME 
    const [renameSuccess, setRenameSuccess] = useState(false);
    const [renameValidationVisible, setRenameValidationVisible] = useState(false);
    const [renameVisible, setRenameVisible] = useState(false);
    const [renameErrorMessage, setRenameErrorMessage] = useState('Unknown Error');
    const [newName, setNewName] = useState('');
    // SET GOAL 
    const [goalSuccess, setGoalSuccess] = useState(false);
    const [goalValidationVisible, setGoalValidationVisible] = useState(false);
    const [goalVisible, setGoalVisible] = useState(false);
    const [goalErrorMessage, setGoalErrorMessage] = useState('Unknown Error');
    const [newGoal, setNewGoal] = useState('');

    const isKpi = (chart.chart_type === 'kpi');
    const isTable = (chart.chart_type === 'table');
    let chart_size_str;

    useEffect(() => {
        const getUserId = async () => {
            const id = await supabase_userid;
            setUserId(id);
        };
        getUserId();
    }, []);

    // DELETE 
    const handleDeleteMessage = () => {
        setDeleteConfirmVisible(true); 
    };
    const handleCloseDeleteMessage = () => {
        setDeleteConfirmVisible(false);
    };
    const handleDelete = async () => {
        setDeleteConfirmVisible(false);
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/charts/${chart.id}`, {
                headers: {
                  'X-User-ID': userId,
                  ...(teamId && { 'X-Team-ID': teamId })
                }
              });
            setDeleteSuccess(true);
        } catch (error) {
            setDeleteErrorMessage(error.response.data.error);
            setDeleteSuccess(false);
            console.error('Error deleting item:', error);
        } finally {
            setDeleteValidationVisible(true);
        }
    };
    const handleCloseValidationMessage = () => {
        setDeleteValidationVisible(false);
        if (deleteSuccess) {
            onReload(); // Refresh dashboard after deletion
        }
      };


    // REFRESH
    const refreshDashboard = async () => {      
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/charts/${chart.id}`, {
                headers: {
                'X-User-ID': userId,
                ...(teamId && { 'X-Team-ID': teamId })
            }
          });
          setRefreshSuccess(true);
          onReload(); // Refresh dashboard
  
        } catch (error) {
          console.error('Error refreshing item:', error);
          setRefreshSuccess(false);
          setRefreshErrorMessage(error.response.data.error);
          setRefreshValidationVisible(true);
        } 
    };
    const handleCloseRefreshValidationMessage = () => {
        setRefreshValidationVisible(false);
      };

    // SHOW QUERY
    const handleShowQuery = () => {  
        setShowQueryVisible(true);
    };
    const handleCloseQuery = () => {
        setShowQueryVisible(false);
    };

    // RENAME
    const renameDashboard = async () => { 
        setRenameVisible(false);     
        try {
          await axios.put(`${process.env.REACT_APP_API_URL}/api/charts/${chart.id}/name`, { name: newName }, { headers: {
            'X-User-ID': userId,
            ...(teamId && { 'X-Team-ID': teamId })
          }});
          setRenameSuccess(true);
          setRenameValidationVisible(true);
        } catch (error) {
          console.error('Error renaming item:', error);
          setRenameSuccess(false);
          setRenameErrorMessage(error.response.data.error);
          setRenameValidationVisible(true);
        } 
    };
    const handleNameInput = (input) => {
        setNewName(input);
      };
    const handleCloseRenameValidationMessage = () => {
        setRenameValidationVisible(false);
        if (renameSuccess) {
            onReload(); // Refresh dashboard after rename
        } else {
            handleRename();
        }
      };
      const handleRename = () => {
        setRenameVisible(true);
      };
      const handleRenameClose = () => {
        setRenameVisible(false);
      };


    // RESIZE
    const resizeDashboard = async (newSize) => { 
        if (newSize !== chart.chart_size) {
            try {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/charts/${chart.id}/resize`, { size: newSize }, { headers: {
                    'X-User-ID': userId,
                    ...(teamId && { 'X-Team-ID': teamId })
                  }});
                onReload();
            } catch (error) {
                console.error('Error resizing item:', error);
            } 
        }
    };

    // SET GOAL
    const updateGoal = async () => { 
        setGoalVisible(false);     
        try {
          await axios.put(`${process.env.REACT_APP_API_URL}/api/charts/${chart.id}/goal`, { goal: newGoal }, { headers: {
            'X-User-ID': userId,
            ...(teamId && { 'X-Team-ID': teamId })
          }});
          setGoalSuccess(true);
          setGoalValidationVisible(true);
        } catch (error) {
          console.error('Error setting goal:', error);
          setGoalSuccess(false);
          setGoalErrorMessage(error.response.data.error);
          setGoalValidationVisible(true);
        } 
    };
    const handleGoalInput = (input) => {
        setNewGoal(input);
      };
    const handleCloseGoalValidationMessage = () => {
        setGoalValidationVisible(false);
        if (goalSuccess) {
            onReload(); // Refresh dashboard after setting goal
        } else {
            handleGoal();
        }
      };
      const handleGoal = () => {
        setGoalVisible(true);
      };
      const handleGoalClose = () => {
        setGoalVisible(false);
      };

    if (isKpi) {
        chart_size_str = 'max-h-80 w-full md:w-1/2 xl:w-1/3 2xl:w-1/4'
    } else if (chart.chart_size === 3) {
        chart_size_str = 'h-80 w-full'
    } else if (chart.chart_size === 2 ) {
        chart_size_str = 'h-80 w-full lg:w-1/2'
    } else {
        chart_size_str = 'h-80 w-full md:w-1/2 2xl:w-1/3'
    }; 
    const selected_size_str = 'hidden md:flex border rounded border-primary text-primary text-xs p-1 mr-1';
    const non_selected_size_str = 'hidden md:flex border rounded border-gray-300 text-gray-300 text-xs p-1 mr-1';

    return (
        <div className={'mb-20 sm:pl-10 sm:pr-10 ' + chart_size_str}>
            {deleteConfirmVisible && <DeleteConfirmMessage onClose={handleCloseDeleteMessage} onDelete={handleDelete} deleteType='item' deleteMessage='This item will be permanently deleted.' />}
            {showQueryVisible && <ShowQuery onClose={handleCloseQuery} queryString={chart.query} />}
            {deleteValidationVisible && <ValidationMessage success={deleteSuccess} onClose={handleCloseValidationMessage} errorMessage={deleteErrorMessage} type="Delete" />}
            {refreshValidationVisible && <ValidationMessage success={refreshSuccess} onClose={handleCloseRefreshValidationMessage} errorMessage={refreshErrorMessage} type="Refresh" />}
            {renameVisible && <InputBox onClose={handleRenameClose} onSubmit={renameDashboard} onUserInput={handleNameInput} title='Rename' negativeCta='Close' positiveCta='Save' startValue={chart.current_state.name} inputValidation={isStringInput} />}
            {renameValidationVisible && <ValidationMessage success={renameSuccess} onClose={handleCloseRenameValidationMessage} errorMessage={renameErrorMessage} type="Rename" />}
            {goalVisible && <InputBox onClose={handleGoalClose} onSubmit={updateGoal} onUserInput={handleGoalInput} title='Set Goal' negativeCta='Close' positiveCta='Save' startValue={chart.current_state.kpi.goal} inputValidation={isNumberInput} />}
            {goalValidationVisible && <ValidationMessage success={goalSuccess} onClose={handleCloseGoalValidationMessage} errorMessage={goalErrorMessage} type="Set Goal" />}
            <div className='flex flex-row justify-between mb-1 space-x-2'>
                <h3><b> {chart.current_state.name} </b></h3>
                <div className='flex items-center whitespace-nowrap'>
                    <div className={`${isKpi ? 'hidden' : 'flex flex-row'}`}>
                        <button onClick={() => resizeDashboard(1)} className={(chart.chart_size === 1) ? selected_size_str : non_selected_size_str}> 1x </button>
                        <button onClick={() => resizeDashboard(2)} className={(chart.chart_size === 2) ? selected_size_str : non_selected_size_str}> 2x </button>
                        <button onClick={() => resizeDashboard(3)} className={(chart.chart_size === 3) ? selected_size_str : non_selected_size_str}> 3x </button>
                    </div>
                    <Menu as="div" className="relative inline-block text-left z-20">
                        <Menu.Button><MoreVertical size={18} /></Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-fit origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none text-xs">
                                <Menu.Item>
                                    {({ active }) => (
                                        <button className={`${active && 'bg-lightprimary'} group flex w-full items-center rounded-md px-5 py-2`} onClick={refreshDashboard} >
                                            <RefreshCw size={10} className='mr-3' />
                                            Refresh
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button className={`${active && 'bg-lightprimary'} group flex w-full items-center rounded-md px-5 py-2`} onClick={handleRename}>
                                            <Edit2 size={10} className='mr-3' />
                                            Rename
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        
                                            <button className={`${active && 'bg-lightprimary'} group ${isKpi ? 'flex' : 'hidden'} w-full items-center rounded-md px-5 py-2`} onClick={handleGoal} >
                                                <Target size={10} className='mr-3' />
                                                Update Goal
                                            </button>
                                        
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        
                                            <button className={`${active && 'bg-lightprimary'} group ${(isKpi | isTable) ? 'hidden' : 'flex'} w-full items-center rounded-md px-5 py-2`} >
                                                <Tool size={10} className='mr-3' />
                                                <Link to={`/dashboard/${chart.id}`}>
                                                    Customize
                                                </Link>
                                            </button>
                                        
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button className={`${active && 'bg-lightprimary'} group flex w-full items-center rounded-md px-5 py-2`} onClick={handleShowQuery} >
                                            <Eye size={10} className='mr-3' />
                                            Show Query
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button className={`${active && 'bg-lightprimary'} group flex w-full items-center rounded-md px-5 py-2 text-red-600`} onClick={handleDeleteMessage}>
                                            <Trash size={10} className='mr-3' />
                                            Delete
                                        </button>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
            <ChartHelper params={chart.current_state} showname={false} colors={colors} />
        </div>
    );
}

export default DashboardItem;
