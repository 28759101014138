import React from 'react';
import { Trash, Edit3, Book } from 'react-feather';
import { Link } from 'react-router-dom';

function FileTableRow({ file, onDelete, onRename }) {
    const handleDeleteClick = () => {
        console.log(file);
        onDelete(file.file_id); // Pass the filename to the onDelete function
    };
    const handleRenameClick = () => {
        onRename(file.file_id, file.filename); // Pass the filename to the onDelete function
    };

    return (
        <tr className='border-b'>
            <td className='px-6 py-3'><Link to={`/files/${file.file_id}`}>{file.filename}</Link></td>
            <td className='px-6 py-3 flex justify-center items-center space-x-2'> 
                <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10'> 
                <Link to={`/files/${file.file_id}/dictionary`}><Book size={18} /> </Link>
                </div> 
            </td>
            <td className='px-6 py-3'>{file.upload_date}</td>
            <td className='px-6 py-3'>{file.file_size}</td>
            <td className='px-6 py-3'>{file.is_shared}</td>
            <td className='px-6 py-3 flex justify-center items-center space-x-2'> 
                <button onClick={handleRenameClick} className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full hover:bg-gray-100 sm:mx-0 sm:h-10 sm:w-10'> 
                    <Edit3 size={18} /> 
                </button> 
                <button onClick={handleDeleteClick} className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full hover:bg-red-100 sm:mx-0 sm:h-10 sm:w-10'> 
                    <Trash size={18} className='text-red-600' /> 
                </button> 
            </td>
        </tr>
    );
}

export default FileTableRow;
