import { useState, useEffect } from 'react';
import axios from 'axios';
import { supabase_userid } from '../utils/supabase-utils';

const useColors = () => {
  const [primary, setPrimary] = useState('#43B4F9');
  const [secondary, setSecondary] = useState('#A945FC');
  const [colorLoading, setColorLoading] = useState(true);

  useEffect(() => {
    const getColors = async () => {
        try {
            const userId = await supabase_userid;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/colors`, {
              headers: {
                'X-User-ID': userId,
              }
            });
            if (response.data) {
                setPrimary(response.data.primary);
                setSecondary(response.data.secondary);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setColorLoading(false);
        }
    };
    getColors();
  }, []);

  return { primary, secondary, colorLoading };
};

export default useColors;
