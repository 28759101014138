import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter } from 'react-table';
import React, { useState, useMemo } from 'react';
import { CSVLink } from 'react-csv';

function DataTable({columnsRaw, data, showCount, exportName}) {

    const [hoveredCellData, setHoveredCellData] = useState(null);

    const columns = useMemo(() => {
        return columnsRaw.map((columnName, index) => ({
            ...columnName, 
            Filter: ({ column }) => (
                <input
                    value={column.filterValue || ''}
                    onChange={(e) => column.setFilter(e.target.value)}
                    placeholder="Search"
                    className='text-sm font-normal border rounded-full border-gray-200 px-3 py-0 focus:border-primary focus:ring-0'
                />
            ),
        }));
    }, [columnsRaw]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        state,
        nextPage,
        previousPage,
        gotoPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: showCount },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    const handleCellHover = (rowData) => {
        setHoveredCellData(rowData);
    };

    const handleCellLeave = (e) => {
        setHoveredCellData(null);
    };

    const csvData = data.map(row => {
        const updatedRow = Object.fromEntries(
          Object.entries(row).map(([key, value]) => [columns[key]['Header'], value])
        );
        return updatedRow;
      });


    return (
        <div className='table-container overflow-x-auto'>
            <div className='flex flex-col sm:flex-row mt-5 mb-5 space-y-2 sm:space-y-0 sm:space-x-2'>
                <input className = "border px-3 text-sm py-0 focus:border-primary focus:ring-0" value = {state.globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)} placeholder='Search table' />
                <CSVLink data={csvData} filename={exportName} className="border border-primary text-primary py-1 px-4 text-xs rounded w-fit whitespace-nowrap"> Export CSV </CSVLink>
            </div>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th key={column.id} className='border px-6 py-3 text-sm'>
                                <button type='button' onClick={() => {column.toggleSortBy();}}>
                                    {column.render('Header')}
                                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                </button>
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                            </th>
                        ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                        <tr className='border border-collapse' {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                // return <td className='border px-6 max-w-xs overflow-y-clip text-sm truncate hover:overflow-visible' {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                return (
                                    <td
                                        className='border px-6 relative max-w-xs text-sm'
                                        {...cell.getCellProps()}
                                        onMouseEnter={() => handleCellHover(cell.value)}
                                        onMouseLeave={handleCellLeave}
                                    >
                                        <div className="truncate">{cell.render('Cell')}</div>
                                        {hoveredCellData && hoveredCellData === cell.value && cell.value.length > 35 && (
                                            <div className="absolute bg-white border border-gray-300 p-2 z-10">
                                                {cell.value} {/* Render remaining text */}
                                            </div>
                                        )}
                                    </td>);
                            })}
                        </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="flex flex-wrap items-center gap-2 my-5 text-sm">
                <div className='flex flex-row gap-1'>
                    <button
                        className="border rounded p-1"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {'<<'}
                    </button>
                    <button
                        className="border rounded p-1"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        {'<'}
                    </button>
                    <button
                        className="border rounded p-1"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                        {'>'}
                    </button>
                    <button
                        className="border rounded p-1"
                        onClick={() => gotoPage(Math.ceil(rows.length / pageSize) - 1)}
                        disabled={!canNextPage}
                    >
                        {'>>'}
                    </button>
                    <span className="flex items-center gap-1">
                        <div>Page</div>
                        <strong>
                        {pageIndex + 1} of {Math.ceil(rows.length / pageSize)}
                        </strong>
                    </span>
                </div>
                <span className="flex items-center gap-1">
                    | Go to page:
                    <input
                        type="number"
                        value={pageIndex + 1}
                        onChange={(e) => { gotoPage(e.target.value ? Number(e.target.value) - 1 : 0); }}
                        className="border p-1 rounded w-16 focus:ring-0 focus:border-primary text-sm"
                    />
                </span>
                <select
                    value={pageSize}
                    onChange={(e) => { setPageSize(Number(e.target.value)); }}
                    className='py-1 focus:ring-0 focus:border-primary text-sm'
                >
                    {[20, 40, 60, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}> Show {pageSize} </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default DataTable;