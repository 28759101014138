import NavBar from '../components/nav';
import { useState } from 'react'
import chat_design from '../assets/chat_design.png'
import modules_design from '../assets/modules_design.png'
import illustration_1 from '../assets/282-Searching-For-Answers.png'
import illustration_2 from '../assets/268-Sales-Target.png'
import illustration_3 from '../assets/256-Inspired-With-Ideas.png'
import axios from 'axios';


function Home() {
  const [inputValue, setInputValue] = useState('');
  const [waitlistSuccess, setWaitlistSuccess] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleChange = (e) => {
      const input = e.target.value;
      setInputValue(input);
    };
  

  const joinWaitlist = async () => { 
      // Email validation
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue);
      if (!isValidEmail) {
        setWaitlistSuccess(false);
        setSubmitMessage('Please enter a valid email address');
      } else {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/waitlist`, { first_name: null, last_name: null, email: inputValue });
          setWaitlistSuccess(true);
          setInputValue('');
          setSubmitMessage(response.data.message);
  
        } catch (error) {
          console.error('Error adding to waitlist', error);
          setWaitlistSuccess(false);
          setSubmitMessage('Something went wrong. Please try again.');
        } 
      }
    
  };

  return (
    <>
        <NavBar />
        <div className="w-full p-10 py-60 sm:pt-80 flex flex-col justify-center items-center space-y-14">
            <div className="w-full text-center flex flex-col space-y-10 items-center">
                <h1 className="text-4xl sm:text-6xl font-semibold">Your AI Product Analyst</h1>
                <p className="leading-relaxed md:max-w-lg font-semibold">No SQL? No problem. Get immediate answers, insights and dashboards to guide your product decision-making.</p>
            </div>
            <div className='flex flex-col space-y-2'>
              <p className='font-semibold'>Get early access</p>
              <div className='flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2 w-full'>
                <div className='flex flex-col'>
                  <input type="text" value={inputValue} onChange={handleChange} placeholder='Enter your e-mail' id='email' name='email' autoComplete='email'
                                              className="block w-full min-w-80 text-sm rounded-md shadow-md py-2 px-3 ring-1 ring-gray-100 focus:ring-2 focus:ring-primary sm:leading-6 focus:border-primary" />  
                  {submitMessage && <span className={`${waitlistSuccess ? 'text-green-500' : 'text-red-500'} text-sm`}>{submitMessage}</span>}
                </div>
                <button onClick={joinWaitlist} 
                  className='justify-center w-full sm:w-fit bg-gradient-to-r from-primary to-secondary text-white py-2 h-fit min-w-40 rounded shadow whitespace-nowrap'> 
                  Join Waitlist
                </button>
              </div>
            </div>
            <img src={modules_design} alt='Lyze modules' className='pt-10 sm:pt-32 w-full max-w-7xl' />
            <img src={chat_design} alt='Lyze chatbot' className='sm:pt-10 w-full max-w-7xl' />
            <div className="w-full text-center flex flex-col space-y-2 sm:space-y-10 items-center pt-10 sm:pt-32">
                <h1 className="text-2xl sm:text-4xl font-semibold">Unleash the full power of your data</h1>
                <div className='flex flex-col md:flex-row md:space-x-20 w-full max-w-7xl justify-evenly'>
                  <div className='flex flex-col space-y-2 items-center'>
                    <img src={illustration_1} alt='Searching for Answers illustration' className='pt-10 w-40 md:w-72' />
                    <p className='max-w-sm'> Get instant answers to your data questions. It's as simple as sending a message </p>
                  </div>
                  <div className='flex flex-col space-y-2 items-center'>
                    <img src={illustration_2} alt='Sales Target illustration' className='pt-10 w-40 md:w-72' />
                    <p className='max-w-sm'> Build dashboards to monitor key metrics and stay on top of your performance </p>
                  </div>
                  <div className='flex flex-col space-y-2 items-center'>
                    <img src={illustration_3} alt='Inspired With Ideas illustration' className='pt-10 w-40 md:w-72' />
                    <p className='max-w-sm'> Custom prompts optimized to understand exactly what you're looking for </p>
                  </div>
                </div>
            </div>
            

        </div>
    </>
  ); 
}

export default Home;
