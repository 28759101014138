import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ValidationMessage from '../components/validation-message';
import { supabase_userid } from '../utils/supabase-utils';
import { useNavigate } from 'react-router-dom';
import useTeamInfo from '../utils/get-team';
import { FileInput } from 'flowbite-react';

function FileUpload({ onUploadSuccess }) {
    const { teamId } = useTeamInfo();
    const [shareFile, setShareFile] = useState(true);
    const [file, setFile] = useState(null);
    const [fileId, setFileId] = useState(null);
    const fileInputRef = useRef(null);
    const [success, setSuccess] = useState(false);
    const [successMessageVisible, setSuccessMessageVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Unknown Error');
    const navigate = useNavigate();

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('shareFile', shareFile);

        try {
            const userId = await supabase_userid;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-User-ID': userId,
                    ...(teamId && { 'X-Team-ID': teamId })
                }
            });
            setFileId(response.data.fileid);
            setSuccess(true);
            // need to call this to refresh data list
            onUploadSuccess();

        } catch (error) {
            setSuccess(false);
            if (error.response && error.response.data && error.response.data.error) {
                setErrorMessage(error.response.data.error);
            } else {
                setErrorMessage('Error uploading file: ' + error.message);
            }
            console.error('Error uploading file:', error);
        } finally {
            setSuccessMessageVisible(true);
        }

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleCloseMessage = () => {
        setSuccessMessageVisible(false);
    };
    const handleShareFile = () => {
        setShareFile(!shareFile);
    };

    useEffect(() => {
        if (fileId && success) {
            navigate(`/files/${fileId}/dictionary`);
        }
    }, [fileId, success, navigate]);

    return (
        <>
            {successMessageVisible && <ValidationMessage success={success} onClose={handleCloseMessage} errorMessage={errorMessage} type="Upload" />}
            <div className="flex flex-col gap-y-4 mt-5">
                {teamId && <div className='flex items-center'>
                    <input type="checkbox" className='appearance-none mr-2 rounded focus:outline-none focus:ring-0
                        checked:bg-primary checked:hover:bg-primary checked:focus:outline-none checked:focus:ring-0 checked:focus:bg-primary' checked={shareFile} onChange={handleShareFile} />
                    <label className='text-sm' > Share with team </label>
                </div>}
                <FileInput id="file-upload-helper-text" helperText="Please updload a CSV file" className='w-full sm:w-72' onChange={handleFileChange} ref={fileInputRef} />
                {/* <input type="file" onChange={handleFileChange} ref={fileInputRef} className='text-sm w-min file:text-center file:py-3 file:px-10 file:rounded-full file:border-0 file:font-semibold file:bg-lightprimary file:text-primary px-10'/> */}
                {/* <div className='text-sm w-min mr-4 py-3 px-10 rounded-full border-0 font-semibold bg-lightprimary text-primary whitespace-nowrap'>Choose File</div>   */}
                <button onClick={handleUpload}  className="w-min px-20 py-3 items-center bg-primary text-white rounded shadow hover:bg-gradient-to-r hover:from-primary hover:to-secondary">Upload</button>
            </div>
        </>
    );
}

export default FileUpload;

