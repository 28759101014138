import FileData from './data-page/file-data';
import FileList from './data-page/file-list';
import FileUpload from './data-page/file-upload';
import AnalyzePage from './analyze-page/analyze';
import Dashboard from './dashboard-page/dashboard';
import Data from './data-page/data';
import Home from './home/home';
import Waitlist from './login/waitlist';
import ChartEdit from './dashboard-page/chart-edit';
import Login from './login/login';
import Signup from './login/signup';
import DataDictionary from './data-page/data-dictionary';
import Settings from './settings-page/settings';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './utils/private-route';
import usePageTracking from './utils/page-tracking';
import Subscribe from './login/subscribe';
import Success from './login/success';


function AppRoutes() {
  usePageTracking();

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route element={<PrivateRoute />}>
        <Route path='/waitlist' element={<Waitlist />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/analyze' element={<AnalyzePage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path='/data' element={<Data />} />
        <Route path='/upload' element={<FileUpload />} />
        <Route path='/filelist' element={<FileList />} />
        <Route path='/files/:fileId' element={<FileData />} />
        <Route path='/files/:fileId/dictionary' element={<DataDictionary />} />
        <Route path='/dashboard/:id' element={<ChartEdit />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/subscribe' element={<Subscribe />} />
        <Route path='/success' element={<Success />} />
      </Route>
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}


export default App;
