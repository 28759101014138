import logo from '../assets/logo.png'
import { Link } from 'react-router-dom'
import { Menu as MenuIcon } from 'react-feather'
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { supabase } from '../utils/supabaseClient';

function NavBar() {

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  return (
    <nav className="navbar fixed top-0 left-0 w-full h-20 flex items-center px-10 bg-white text-darkgrey z-30 shadow-sm">
      <div className="absolute flex flex-1 items-center mr-8">
        <Link to="/"><img src={logo} alt="Logo" className="h-11 mr-4" /></Link>
        <Link to="/" className='hidden sm:flex text-2xl font-medium'> <h1>Lyze AI</h1> </Link>
      </div>

      {/* WEB SCREENS */}
      <div className={`hidden sm:flex space-x-10 items-center justify-center w-full`}>
          <Link to="/dashboard" className="text-md flex items-center justify-center">Dashboard</Link>
          <Link to="/analyze" className="text-md flex items-center justify-center">Analyze</Link>
          <Link to="/data" className="text-md flex items-center justify-center">Data</Link>
          <Link to="/settings" className="text-md flex items-center justify-center">Settings</Link>
      </div>        
      {/* <button className="absolute mr-8 right-0 hidden sm:flex items-center overflow-hidden bg-white py-2 px-10 rounded shadow"><Link to="/">Logout</Link></button> */}
      <button onClick = {handleLogout} className="absolute mr-8 right-0 hidden sm:flex items-center overflow-hidden bg-white py-2 px-10 rounded shadow">Logout</button>

      {/* MOBILE SCREENS */}
      <div className='sm:hidden flex items-center justify-end w-full whitespace-nowrap'>
        <Menu as="div" className="relative inline-block">
            <Menu.Button><MenuIcon /></Menu.Button>
            <Transition as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 mt-2 w-fit origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none text-md">
                    <Menu.Item>
                        {({ active }) => ( <Link to="/dashboard" className={`${active && 'bg-lightprimary'} group flex rounded-md items-center justify-center px-5 py-2`}>Dashboard</Link> )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => ( <Link to="/analyze" className={`${active && 'bg-lightprimary'} group flex rounded-md items-center justify-center px-5 py-2`}>Analyze</Link> )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => ( <Link to="/data" className={`${active && 'bg-lightprimary'} group flex rounded-md items-center justify-center px-5 py-2`}>Data</Link> )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => ( <Link to="/settings" className={`${active && 'bg-lightprimary'} group flex rounded-md items-center justify-center px-5 py-2`}>Settings</Link> )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => ( <Link to="/" className={`${active && 'bg-lightprimary'} group flex rounded-md items-center justify-center px-5 py-2 text-red-600`}>Logout</Link> )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
      </div>
    </nav>
  );
}

export default NavBar;
