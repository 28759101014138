import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { supabase } from './supabaseClient';
import axios from 'axios';

const PrivateRoute = () => {
    const location = useLocation();
    const [authState, setAuthState] = useState({
        isAuthenticated: null,
        hasSubscription: false,
        loading: true,
    });
    

    useEffect(() => {
        const fetchData = async () => {
            const { data: { user } } = await supabase.auth.getUser();
            if (user) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/subscription-status/${user.id}`);
                    setAuthState({
                        isAuthenticated: true,
                        hasSubscription: response.data.has_subscription,
                        loading: false,
                    });
                    console.log(response);
                }
                catch (error) {
                    console.error('Error checking subscription status:', error);
                    setAuthState((prevState) => ({
                        ...prevState,
                        loading: false,
                    }));
                } 
            } else {
                setAuthState({
                    isAuthenticated: false,
                    hasSubscription: false,
                    loading: false,
                });
            };
        };
        const queryParams = new URLSearchParams(location.search);
        const sessionId = queryParams.get('session_id');
        if (sessionId || authState.isAuthenticated === null) {
            fetchData();
        }
    }, [location, authState.isAuthenticated]);


    const { isAuthenticated, hasSubscription, loading } = authState;

    if (isAuthenticated === null || loading) {
        return <div>Loading...</div>;
    }
    const logoutRouteOnly = ['/waitlist', '/login', '/signup'].includes(location.pathname);
    const queryParams = new URLSearchParams(location.search);
    const hasSessionId = queryParams.has('session_id');

    if (isAuthenticated && !hasSubscription && !hasSessionId && !['/subscribe'].includes(location.pathname)) {
        return <Navigate to={"/subscribe"} replace state={{ path: location.pathname }} />
    }
    if (isAuthenticated && logoutRouteOnly) {
        return <Navigate to={"/dashboard"} replace state={{ path: location.pathname }} />
    }
    if (isAuthenticated && hasSubscription && ['/subscribe'].includes(location.pathname)) {
        return <Navigate to={"/dashboard"} replace state={{ path: location.pathname }} />
    }
    if (logoutRouteOnly || isAuthenticated) {
        return <Outlet />
    }
    return <Navigate to={"/login"} replace state={{ path: location.pathname }} /> 
};

export default PrivateRoute;
