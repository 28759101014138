import React, { useRef, useState } from 'react';
import Dropdown from '../components/dropdown';
import { chartTypes } from '../types/types';
import { isStringInput } from '../utils/utils';

// Adding a new module:
// - create a const for the sub-inputs/sub-text for that module
// - add it to componentsMap in Modules function below 
// - update the query generation in createData, make sure addtlInfo['type'] is filled out correctly
// - add respective item in types.js and helper.py respectively
// - update handling in send-message API code in app.py
// - create function in helper.py to process sql query

const General = () => (
    <div></div>
  );

const Metrics = React.forwardRef((props, ref) => {
    const timeframes = [
        { item: 'Monthly' },
        { item: 'Daily' },
        { item: 'Annual' },
      ] 
    const [selectedTimeFrame, setSelectedTimeFrame] = useState(timeframes[0]);
    const handleTimeFrameChange = (newTimeFrame) => {
        setSelectedTimeFrame(newTimeFrame);
    };
    React.useImperativeHandle(ref, () => ({
        getInputText: () => `{ 'timeframe': '${selectedTimeFrame.item}' }`
    }));
    return (
        <div className='flex flex-row flex-wrap space-y-2 sm:space-y-0 sm:space-x-2'>
            <Dropdown defaultValue={selectedTimeFrame} selection={timeframes} onChange={handleTimeFrameChange} width={'w-full sm:w-48 '} height={'max-h-28'} fontSize={'text-xs'} />
            {/* <input className="w-full sm:w-96 text-xs px-4 rounded-lg bg-white pl-3 pr-10 text-left border border-gray-200 focus:ring-1 focus:ring-primary focus:border-primary" 
                    type="text" placeholder="Timeframe (e.g. this month, last year, Mar 21 - Oct 21)" value={timeFrame} inputValidation={isStringInput} onChange={(e) => setTimeFrame(e.target.value)} /> */}
        </div>
    )
  });
  
const Graphing = React.forwardRef((props, ref) => {
    const [xAxis, setXAxis] = useState('');
    const [yAxis, setYAxis] = useState('');
    const [selectedChartType, setSelectedChartType] = useState(chartTypes[0])
    const handleChartTypeChange = (newChartType) => {
        setSelectedChartType(newChartType);
    };
    React.useImperativeHandle(ref, () => ({
        getInputText: () => `{ 'chart type': '${selectedChartType.item}', 'x-axis': '${!xAxis.trim() ? 'Auto' : xAxis}', 'y-axis': '${!yAxis.trim() ? 'Auto' : yAxis}' }`
    }));

    return (
        <div className='flex flex-row flex-wrap space-y-2 sm:space-y-0 sm:space-x-2'>
            <Dropdown defaultValue={selectedChartType} selection={chartTypes} onChange={handleChartTypeChange} width={'w-full sm:w-48 '} height={'max-h-28'} fontSize={'text-xs'} />
            <input className="w-full sm:w-48 text-xs px-4 rounded-lg bg-white pl-3 pr-10 text-left border border-gray-200 focus:ring-1 focus:ring-primary focus:border-primary" 
                type="text" placeholder="X-Axis" value={xAxis} inputValidation={isStringInput} onChange={(e) => setXAxis(e.target.value)} />
            <input className="w-full sm:w-48 text-xs px-4 rounded-lg bg-white pl-3 pr-10 text-left border border-gray-200 focus:ring-1 focus:ring-primary focus:border-primary" 
                type="text" placeholder="Y-Axis" value={yAxis} inputValidation={isStringInput} onChange={(e) => setYAxis(e.target.value)} />
        </div>
    )
});

const MainInput = ({ placeholder, handleSubmit }) => {
    const [inputText, setInputText] = useState('');

    const handleSend = () => {
        handleSubmit(inputText);
        setInputText('');
    };
    return (
        <div className='flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0 sm:space-x-2'>
            <input
                type="text"
                className="w-full px-4 py-2 border rounded-md ring-1 ring-gray-100 focus:ring-2 focus:ring-primary focus:border-primary"  
                placeholder={placeholder}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
            />
            <button className="px-4 py-2 bg-darkgrey text-white rounded-md hover:bg-primary w-full sm:w-fit" onClick={handleSend}>
                Send
            </button>
        </div>
    )
};
  
const createData = (mainInputText, subInputText, module) => {
    let query = mainInputText;
    let addtlInfo = {};
    if (module === 'General') {
        query = `write a postgresql query to answer the following question. Please leave the title as an empty string: ${mainInputText}`;
        addtlInfo = {
            'type': 'Table',
        };
    }
    if (module === 'Graphing') {
        const parsedDict = JSON.parse(subInputText.replace(/'/g, '"'));
        query = `write a postgresql query to build a graph for the following question, where the first column is ${parsedDict['x-axis'] === 'Auto' ? 'x-axis' : parsedDict['x-axis']} ` +
            `and second column is ${parsedDict['y-axis'] === 'Auto' ? 'y-axis' : parsedDict['y-axis']}. The title should be a relevant title for this graph: ` +
            `${mainInputText}`;
        addtlInfo = {
            'type': parsedDict['chart type'],
        };
    }
    if (module === 'Metrics') {
        const parsedDict = JSON.parse(subInputText.replace(/'/g, '"'));
        query = `write a postgresql query to get the ${parsedDict['timeframe']} breakdown of the following metric, where the first column is time and second column is the metric value for that timeframe in cronological order. The title should be "${parsedDict['timeframe']}" + the metric pulled: ` +
            `${mainInputText}`;
        addtlInfo = {
            'type': 'KPI',
        };
    }

    const data = {
        'module': module,
        'query': query,
        'addtl': addtlInfo,
    }
    console.log(data);
    return data;
};
  
//   MAIN MODULES HANDLING
function Modules({ module, handleSubmit }) {
    const inputRef = useRef(null);
    const componentsMap = {
        'General': {'component': <General />, 'placeholder': 'What do you want to know?'},
        'Graphing': {'component': <Graphing ref={inputRef} />, 'placeholder': 'What do you want to visualize?'},
        'Metrics': {'component': <Metrics ref={inputRef} />, 'placeholder': 'What kind of metric are you looking for?'},
      };
    
    const RenderedComponent = componentsMap[module]?.component || <General />;

    const handleSend = (mainInputText) => {
        const subInputText = inputRef.current?.getInputText() || '';
        console.log(subInputText);
        const data = createData(mainInputText, subInputText, module);
        handleSubmit(mainInputText, subInputText, data);
    };
   
    
    return (
        <div className='flex flex-col space-y-4'>
          {RenderedComponent}
          <MainInput placeholder={componentsMap[module]?.placeholder} handleSubmit={handleSend} />
        </div>
    );
};
  
export default Modules;