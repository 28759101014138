import logo from '../assets/logo.png'
import {Link} from 'react-router-dom'
import { Menu as MenuIcon } from 'react-feather'
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'

function NavBar() {
  return (
    <nav className="navbar fixed top-0 left-0 w-full h-20 flex items-center justify-between px-10 bg-white text-darkgrey z-30 shadow-sm">
      {/* WEB SCREENS */}
      <div className="absolute flex flex-1 items-center mr-8">
        <Link to="/"><img src={logo} alt="Logo" className="h-11 mr-4" /> </Link>
        <Link to="/" className='hidden sm:flex text-2xl font-medium'> <h1>Lyze AI</h1> </Link>
      </div>
      {/* <div className={`hidden sm:flex space-x-10 items-center justify-center w-full`}>
        <Link to="/" className="text-md flex items-center justify-center">Home</Link>
      </div>  */}
      <div className='absolute mr-8 right-0 hidden sm:flex items-center overflow-hidden gap-x-2'>
        <button className="text-primary font-semibold py-2 px-10 rounded"><Link to="/waitlist">Join Waitlist</Link></button>
        <button className="bg-primary text-white font-semibold py-2 px-10 rounded shadow transition-all duration-500 ease-in-out hover:bg-gradient-to-r hover:from-primary hover:to-secondary">
          <a href="https://calendly.com/sharonye-lyzeai/30min" target="_blank" rel="noreferrer noopener">Book Demo</a>
        </button>
      </div>

      {/* MOBILE SCREENS */}
      <div className='sm:hidden flex items-center justify-end w-full whitespace-nowrap'>
        <Menu as="div" className="relative inline-block">
            <Menu.Button><MenuIcon /></Menu.Button>
            <Transition as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 mt-2 w-fit origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none text-md">
                    {/* <Menu.Item>
                        {({ active }) => ( <Link to="/" className={`${active && 'bg-lightprimary'} group flex rounded-md items-center justify-center px-5 py-2`}>Home</Link> )}
                    </Menu.Item> */}
                    <Menu.Item>
                        {({ active }) => ( <Link to="/waitlist" className={`${active && 'bg-lightprimary'} group flex rounded-md items-center justify-center px-5 py-2`}>Join Waitlist</Link> )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => ( <a href="https://calendly.com/sharonye-lyzeai/30min" target="_blank" rel="noreferrer noopener" className={`${active && 'bg-lightprimary'} group flex rounded-md items-center justify-center px-5 py-2`}>Book Demo</a> )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
      </div>
    </nav>
  );
}

export default NavBar;
