export const chartTypes = [
    { item: 'Line' },
    { item: 'Bar' },
    { item: 'Pie' },
    { item: 'Donut' },
    { item: 'Stacked' },
    { item: '100% Stacked' },
  ]
export const filterTypes = [
    {item: 'contains'},
    {item: 'does not contain'},
    {item: 'is exactly'},
  ]
export const fieldTypes = [
    {item: '-- select field --'},
    {item: 'Option 1'},
    {item: 'Option 2'},
  ]
export const propertyTypes = [
    {item: '-- select properties --'},
    {item: 'Option 1'},
    {item: 'Option 2'},
  ]

// IMPORTANT: any changes to this should be reflected in the chart_mapping function in helper.py 
export const chartTypeMap = {
    'line':'Line',
    'bar':'Bar',
    'pie':'Pie',
    'donut':'Donut',
    'stack':'Stacked',
    'stack_100':'100% Stacked',
    'table':'Table',
    'kpi':'KPI',
  }
export const modulesList = [
  { id: 1, name: 'General' },
  { id: 2, name: 'Graphing' },
  { id: 3, name: 'Metrics' },
];