import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import FileTableRow from './file-table-row';
import DeleteConfirmMessage from '../components/delete-confirm';
import { supabase_userid } from '../utils/supabase-utils';
import InputBox from '../components/input-box';
import ValidationMessage from '../components/validation-message';
import useTeamInfo from '../utils/get-team';

function FileList({ fileListUpdated }) {
    const [userId, setUserId] = useState(null);
    const { teamId, teamLoading } = useTeamInfo();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    // DELETE
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const [deleteValidationVisible, setDeleteValidationVisible] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('Unknown Error');
    const [deleteFile, setDeleteFile] = useState(null);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    // RENAME
    const [renameSuccess, setRenameSuccess] = useState(false);
    const [renameValidationVisible, setRenameValidationVisible] = useState(false);
    const [renameVisible, setRenameVisible] = useState(false);
    const [renameErrorMessage, setRenameErrorMessage] = useState('Unknown Error');
    const [renameFileId, setRenameFileId] = useState(null);
    const [renameFileName, setRenameFileName] = useState('');
    const [newName, setNewName] = useState('');

    useEffect(() => {
        const getUserId = async () => {
            const id = await supabase_userid;
            setUserId(id);
        };
        getUserId();
    }, []);

    const fetchFiles = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/files`, {
                headers: {
                  'X-User-ID': userId,
                  ...(teamId && { 'X-Team-ID': teamId })
                }
              });
            setFiles(response.data.files);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    }, [teamId, userId]);

    useEffect(() => {
        if (userId === null) return; 
        fetchFiles();
    }, [fileListUpdated, teamLoading, fetchFiles, userId]);



    if (loading || teamLoading) {
        return <div>Loading...</div>;
    }

    if (files.length === 0) {
        return <div>No uploaded data</div>;
    }

    // DELETE
    const handleDelete = async () => {
        setDeleteConfirmVisible(false);
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/files/${deleteFile}`, {
                headers: {
                  'X-User-ID': userId,
                  ...(teamId && { 'X-Team-ID': teamId })
                }
              });
              setDeleteSuccess(true);
        } catch (error) {
            setDeleteSuccess(false);
            setDeleteErrorMessage(error.response.data.error);
            console.error('Error deleting file:', error);
        } finally {
            setDeleteFile('');
            setDeleteValidationVisible(true);
        }
    };
    const handleCloseMessage = () => {
        setDeleteFile(null);
        setDeleteConfirmVisible(false);
    };
    const handleShowMessage = (fileId) => {
        setDeleteFile(fileId);
        setDeleteConfirmVisible(true);
    };
    const handleCloseValidationMessage = () => {
        setDeleteValidationVisible(false);
        if (deleteSuccess) {
            fetchFiles(); // Refresh the list of files after deletion
        }
      };


    // RENAME
    const renameFile = async () => { 
        setRenameVisible(false);     
        try {
          await axios.put(`${process.env.REACT_APP_API_URL}/api/files/${renameFileId}/name`, { name: newName }, { headers: {
            'X-User-ID': userId,
            ...(teamId && { 'X-Team-ID': teamId })
          }});
          setRenameSuccess(true);
          setRenameFileName('');
          setRenameFileId(null);
        } catch (error) {
          console.error('Error renaming file:', error);
          setRenameSuccess(false);
          setRenameErrorMessage(error.response.data.error);
        } finally {
            setRenameValidationVisible(true);
        }
    };
    const handleNameInput = (input) => {
        setNewName(input);
      };
    const handleCloseRenameValidationMessage = () => {
        setRenameValidationVisible(false);
        if (renameSuccess) {
            fetchFiles(); // Refresh list after rename
        } else {
            handleRename(renameFileId, renameFileName);
        }
      };
      const handleRename = (fileId, fileName) => {
        setRenameFileId(fileId);
        setRenameFileName(fileName);
        setRenameVisible(true);
      };
      const handleRenameClose = () => {
        setRenameVisible(false);
      };
      // INPUT VALIDATIONS
      const isAcceptableInput = (value) => {
        if (value === '') { return true; }
        return /^[^\\/:*?"<>|]+$/.test(value);
      }

    return (
        <div>
            {deleteConfirmVisible && <DeleteConfirmMessage onClose={handleCloseMessage} onDelete={handleDelete} deleteType='data' deleteMessage='This data and all associated dashboard charts will be permanently deleted.' />}
            {deleteValidationVisible && <ValidationMessage success={deleteSuccess} onClose={handleCloseValidationMessage} errorMessage={deleteErrorMessage} type="Delete" />}
            {renameVisible && <InputBox onClose={handleRenameClose} onSubmit={renameFile} onUserInput={handleNameInput} title='Rename' negativeCta='Close' positiveCta='Save' startValue={renameFileName} inputValidation={isAcceptableInput} />}
            {renameValidationVisible && <ValidationMessage success={renameSuccess} onClose={handleCloseRenameValidationMessage} errorMessage={renameErrorMessage} type="Rename" />}
            <table className='w-full text-left'>
                <thead className='bg-lightgrey text-white'>
                    <tr>
                        <th scope='col' className='px-6 py-3'>File Name</th>
                        <th scope='col' className='px-6 py-3 flex justify-center items-center'>Dictionary</th>
                        <th scope='col' className='px-6 py-3'>Upload Date</th>
                        <th scope='col' className='px-6 py-3'>File Size</th>
                        <th scope='col' className='px-6 py-3'>Shared</th>
                        <th scope='col' className='px-6 py-3 flex justify-center items-center'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {files.map((file, index) => (
                        <FileTableRow key={index} file={file} onDelete={handleShowMessage} onRename={handleRename} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default FileList;
