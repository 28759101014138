import NavBar from '../components/dashboard-nav';
import FileUpload from './file-upload';
import FileList from './file-list';
import React, { useState, useEffect } from 'react';

function Data() {

  const [fileListUpdated, setFileListUpdated] = useState(false);

    const handleFileUploadSuccess = () => {
        // Set fileListUpdated to true to trigger a re-render of the FileList component
        setFileListUpdated(true);
    };

    // Reset fileListUpdated back to false after FileList has processed the update
    useEffect(() => {
      if (fileListUpdated) {
          setFileListUpdated(false);
      }
    }, [fileListUpdated]);

  return (
    <>
      <NavBar />
      <div className="mt-20 p-10 sm:p-20 flex flex-col gap-x-6 gap-y-20">
        <div className='flex flex-col gap-y-3'>
            <h1 className="text-4xl"> New </h1>
            <p className=""> Upload a .csv file </p>
            <FileUpload onUploadSuccess={handleFileUploadSuccess} />
        </div>
        <div className='flex flex-col gap-y-3 overflow-x-auto'>
            <h1 className="text-4xl"> Recent </h1>
            <FileList fileListUpdated={fileListUpdated} />
        </div>
      </div>
    </>
  );
}

export default Data;
