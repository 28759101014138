import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import NavBar from "../components/dashboard-nav";
import logo from '../assets/logo.png'
import axios from 'axios';
import { supabase_userid } from '../utils/supabase-utils';

const Subscribe = () => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    const [errorMessage, setErrorMessage] = useState('');


    const handleClick = async () => {
        const userId = await supabase_userid;
        const stripe = await stripePromise;
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/create-checkout-session`, {}, {
            headers: { 'X-User-ID': userId }
        });
        const result = await stripe.redirectToCheckout({
            sessionId: response.data.sessionId,
          });
        if (result.error) {
            setErrorMessage(result.error);
            console.error(result.error);
        }
    };

    return (
        <>
            <NavBar />
            <div className="flex items-center justify-center w-full h-dvh">
                <div className="flex flex-col w-3/4 max-w-md h-fit shadow-lg rounded-xl p-14 bg-lightprimary">
                    <div>
                        <img src={logo} alt="Logo" className="h-24" />
                    </div>
                    <h1 className="text-3xl font-semibold mt-5 mb-20">Lyze AI</h1>
                    <p className="text-lightgrey pb-2">14-day free trial, then</p>
                    <div className="flex gap-x-2 items-end pb-10">
                        <p className="text-5xl">$10</p>
                        <p className="text-lightgrey">/ month</p>
                    </div>
                    <button onClick={handleClick}
                        className='block w-full relative overflow-hidden bg-primary text-white font-semibold py-3 rounded shadow'>
                        Start free trial
                    </button>
                    {errorMessage && <span className='text-red-500 text-sm'>{errorMessage}</span>}
                </div>
            </div>
        </>
    );
};

export default Subscribe;
