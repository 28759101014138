

// INPUT VALIDATIONS
export const isStringInput = (value) => {
    if (value === '') { return true; }
    return /^[0-9a-zA-Z\s.,_[\]()&%$#'-]+$/.test(value);
}
export const isNumberInput = (value) => {
    if (value === '') { return true; }
    return /^[0-9]+(\.[0-9]+)?$/.test(value);
}
