import BasicChart from "./basic-chart";
import DataTable from "../data-page/data-table";
import KeyResult from "./key-result";

// function ChartHelper ({ params, showname, colors }) {
const ChartHelper = ({ params, showname, colors = { primary: '#43B4F9', secondary: '#A945FC' } }) => {

    const chartType = params['chart_type'];
    
    if (chartType === 'table') {
      return ( <div className="overflow-y-auto max-h-full"><DataTable columnsRaw = {params.table.columns} data = {params.table.data} showCount = {20} exportName={params.name + '.csv'} /></div> ); 
    } 
    if (chartType === 'kpi') {
      return ( <KeyResult data={params} showname={showname} color={colors.primary} /> ); 
    } 

    // if (['line', 'bar', 'pie', 'donut', 'funnel'].includes(chartType)) {
    return ( <BasicChart chartType={chartType} data={params} showname={showname} colors={colors} /> );
    // return ( <Tremor chartType={chartType} data={params} showname={showname} /> );
    // }

    // return null;
  };

export default ChartHelper;