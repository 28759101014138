import NavBar from '../components/nav';
import { useState } from 'react'
import {Link} from 'react-router-dom'
import { supabase } from '../utils/supabaseClient';

function Login() {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [submitMessage, setSubmitMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { error } = await supabase.auth.signInWithPassword( formData );
            if (error) throw error;
            window.location.reload();
        } catch (error) {
            console.error('Error:', error);
            setSubmitMessage(error.message);
            setFormData({ email: '', password: '' });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <>
            <NavBar />
            <form className='flex flex-col items-center justify-center h-dvh p-10' onSubmit={handleSubmit}>
                <div className="w-full sm:max-w-md space-y-5 sm:grid-cols-6">
                    <div className=" text-center">
                        <h1 className="text-3xl font-semibold">Login</h1>
                    </div>
                    <div className=" w-full">
                        <label htmlFor="email" className="block text-sm font-medium leading-6"> Email address </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="block w-full rounded-md py-3 px-3 shadow-md ring-1 ring-gray-100 focus:ring-2 focus:ring-primary text-sm sm:leading-6 focus:border-primary"
                            />
                        </div>
                    </div>
                    <div className="">
                        <label htmlFor="password" className="block text-sm font-medium leading-6 "> Password </label>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                className="block w-full rounded-md py-3 px-3 shadow-md ring-1 ring-gray-100 focus:ring-2 focus:ring-primary text-sm sm:leading-6 focus:border-primary"
                            />
                        </div>
                    </div>
                    <div className="mb-10">
                        <button type="submit"
                            className='block w-full relative overflow-hidden bg-primary text-white font-semibold py-3 rounded shadow transition-all hover:bg-gradient-to-r hover:from-primary hover:to-secondary'>
                            Login
                        </button>
                        {submitMessage && <span className='text-red-500 text-sm'>{submitMessage}</span>}
                    </div>
                    <div>
                        <Link to="/signup" className="text-xs underline text-secondary">Create an account here</Link>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Login;
