import { Listbox } from '@headlessui/react'
import { ChevronDown, Check } from 'react-feather';

function Dropdown({ defaultValue, selection, onChange, width, height, fontSize }) {
    return (
        <Listbox value={defaultValue} onChange={onChange}>
            <div className="relative mt-1">
                <Listbox.Button className={`relative cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border max-w-full ${width} ${fontSize}`}>
                    <span className="block truncate">{defaultValue.item}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        < ChevronDown size={15} />
                    </span>
                </Listbox.Button>
                <Listbox.Options className={`absolute mt-1 bg-white overflow-auto rounded-md py-1 shadow z-10 max-w-full ${width} ${height} ${fontSize}`}>
                {selection.map((item, id) => (
                    <Listbox.Option key={id} className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-lightprimary' : ''}`} value={item}>
                    {({ selected }) => (
                        <>
                        <span className={`block truncate ${ selected ? 'font-medium' : 'font-normal'}`}>
                            {item.item}
                        </span>
                        {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                            < Check size={15} />
                            </span>
                        ) : null}
                        </>
                    )}
                    </Listbox.Option>
                ))}
                </Listbox.Options>
            </div>
        </Listbox>
    );
}

export default Dropdown;
